.dashboard-layout-index-wrap {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.dashboard-layout-index-wrap .header-wrap-index-box {
  min-height: 6.5rem;
  width: 100%;
  position: relative;
  z-index: 9;
}
.dashboard-layout-index-wrap .header-wrap-index-box .size-wrap-global {
  height: 100%;
}
.dashboard-layout-index-wrap .main-wrap-index-box {
  flex: 1;
  overflow-y: scroll;
  padding: 2rem 0rem 1rem 0rem;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;
  z-index: 8;
}
.dashboard-layout-index-wrap .main-wrap-index-box::-webkit-scrollbar {
  display: none;
}
.dashboard-layout-index-wrap .main-wrap-index-box .size-wrap-global-two {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard-layout-index-wrap .footer-wrap-index-box {
  min-height: 5rem;
  display: flex;
  justify-content: center;
  padding: 0rem 0rem 1.2rem 0rem;
}
.dashboard-layout-index-wrap .footer-wrap-index-box .size-wrap-global {
  display: flex;
  justify-content: center;
}
.dashboard-layout-index-wrap .footer-wrap-index-box .size-wrap-global .power-by-box {
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  padding: 1rem 2rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
}
.dashboard-layout-index-wrap .footer-wrap-index-box .size-wrap-global .power-by-box .text {
  font-size: 1.15rem;
}
.dashboard-layout-index-wrap .footer-wrap-index-box .size-wrap-global .power-by-box .img-wrap-box .img-box {
  width: 8rem;
  height: 1.2rem;
}
.dashboard-layout-index-wrap .footer-wrap-index-box .size-wrap-global .power-by-box .img-wrap-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=DashboardLayout.css.map */