.login-page-index-wrap {
  width: 45rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
@media only screen and (max-width: 58em) {
  .login-page-index-wrap {
    width: 100%;
    margin-top: 1rem;
  }
}
.login-page-index-wrap .form-wrap-box-one {
  width: 100%;
  background-color: #f8fcff;
  padding: 1rem;
  border-radius: 1.2rem;
}
.login-page-index-wrap .form-wrap-box-one .inner-wrap {
  width: 100%;
  min-height: 35rem;
  border-radius: 1.2rem;
  background-color: #ffffff;
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
}
.login-page-index-wrap .form-wrap-box-one .inner-wrap .sign-title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 3rem;
  padding: 0rem 2rem;
}
.login-page-index-wrap .form-wrap-box-one .inner-wrap .form {
  padding: 0rem 2rem;
}
.login-page-index-wrap .form-wrap-box-one .inner-wrap .btn-submit-box {
  margin-top: auto;
  padding: 1.5rem 0rem 0rem 0rem;
  border-top: 0.15rem solid rgba(208, 213, 221, 0.5);
}
.login-page-index-wrap .form-wrap-box-one .inner-wrap .btn-submit-box .btn .input-submit {
  background: var(--primary-base-color);
}
.login-page-index-wrap .contact-box-wrap {
  margin-top: auto;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.login-page-index-wrap .contact-box-wrap .text {
  text-align: center;
}
.login-page-index-wrap .contact-box-wrap .text span {
  font-weight: 700;
  cursor: pointer;
  color: var(--primary-base-color);
  margin-left: 0.5rem;
}

.form-modal-auth-login {
  display: flex;
  flex-direction: column;
}
.form-modal-auth-login .select-wrap-account-auth {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 35rem;
  overflow-y: scroll;
}
.form-modal-auth-login .select-wrap-account-auth::-webkit-scrollbar {
  display: none;
}
.form-modal-auth-login .select-wrap-account-auth .select-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 0.2rem solid rgb(238, 238, 238);
  cursor: pointer;
}
.form-modal-auth-login .select-wrap-account-auth .select-item .name {
  color: #020202;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}
.form-modal-auth-login .select-wrap-account-auth .select-item .name .status-type-wrap {
  background-color: #fff6ed;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #ea872d;
  margin-left: 2rem;
}
.form-modal-auth-login .select-wrap-account-auth .select-item .name .status-type-wrap span {
  font-size: 1.2rem;
}
.form-modal-auth-login .select-wrap-account-auth .select-item .check-wrap {
  margin-left: auto;
  border: 0.2rem solid rgb(238, 238, 238);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
}
.form-modal-auth-login .select-wrap-account-auth .select-item .check-wrap::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
}
.form-modal-auth-login .select-wrap-account-auth .select-item .check-wrap-active {
  background-color: rgb(117, 90, 226);
}
.form-modal-auth-login .select-wrap-account-auth .select-item .img-avatar-wrap {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
}
.form-modal-auth-login .select-wrap-account-auth .select-item .img-avatar-wrap .img-box {
  width: 100%;
  height: 100%;
}
.form-modal-auth-login .select-wrap-account-auth .select-item .img-avatar-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.form-modal-auth-login .select-wrap-account-auth .select-item .img-avatar-wrap .avatar-box {
  width: 100%;
  height: 100%;
  background-color: #020202;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-modal-auth-login .select-wrap-account-auth .select-item .img-avatar-wrap .avatar-box span {
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  display: inline-block;
}
.form-modal-auth-login .select-wrap-account-auth .select-item .img-avatar-wrap .avatar-box-two {
  background-color: unset;
}
.form-modal-auth-login .select-wrap-account-auth-in-app .select-item .img-avatar-wrap .img-box {
  background-color: #020202 !important;
}
.form-modal-auth-login .select-wrap-account-auth-in-app .select-item .img-avatar-wrap .avatar-box {
  background-color: #020202 !important;
}
.form-modal-auth-login .pin-group .pin_field_group {
  width: 85%;
}
.form-modal-auth-login .pin-group .black-white-color {
  color: #020202;
}
.form-modal-auth-login .pin-group .resend-text {
  color: var(--primary-purple);
  margin-bottom: 0rem;
  align-self: flex-start;
  margin-top: 2rem;
  cursor: pointer;
  font-weight: 600;
}
.form-modal-auth-login .resend-text {
  color: var(--primary-purple);
  margin-bottom: 0rem;
  align-self: flex-end;
  cursor: pointer;
  font-weight: 600;
}
.form-modal-auth-login .text-box {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.form-modal-auth-login .text-box .small-title {
  color: #676767;
  font-size: 1.45rem;
  font-weight: 400;
}
.form-modal-auth-login .text-box .small-title span {
  color: var(--primary-purple);
  font-weight: 600;
}
.form-modal-auth-login .text-box .big-title {
  font-size: 2rem;
  color: #020202;
  font-weight: 700;
}/*# sourceMappingURL=LoginPage.css.map */