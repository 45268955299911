.dashboard-layout-index-wrap-no-config {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.dashboard-layout-index-wrap-no-config .header-wrap-index-box {
  min-height: 6.5rem;
  width: 100%;
  position: relative;
  z-index: 9;
}
.dashboard-layout-index-wrap-no-config .header-wrap-index-box .size-wrap-global {
  height: 100%;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box {
  flex: 1;
  overflow-y: scroll;
  padding: 2rem 0rem 1rem 0rem;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;
  z-index: 8;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box::-webkit-scrollbar {
  display: none;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index {
  height: calc(100vh - 22rem);
  display: grid;
  place-items: center;
  width: 100%;
  border-radius: 1.6rem;
  padding: 1.2rem;
  margin: auto 0rem;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .loading-wrap {
  width: 20rem;
  height: 20rem;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .loading-wrap .img-box {
  width: 100%;
  height: 100%;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .loading-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer {
  width: 100%;
  height: 100%;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .top-box-wrap {
  flex: 0 0 50%;
  background-image: url("../../assets/empty-bg-lines.png");
  background-position: center;
  background-size: 110%;
  background-repeat: no-repeat;
  position: relative;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .top-box-wrap::after {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-40%, -30%);
  content: "";
  width: 40%;
  height: 100%;
  background-image: url("../../assets/No_Terminal_png 1no-config-center-img.png");
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 48em) {
  .dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .top-box-wrap::after {
    width: 100%;
    height: 100%;
  }
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .bottom-box-wrap {
  flex: 1;
  border-top-right-radius: 3rem;
  border-top-left-radius: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .round-img-box {
  width: 19rem;
  height: 19rem;
  border-radius: 50%;
  padding: 1.4rem;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: none;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .round-img-box .round-grey-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .round-img-box .round-grey-img .img-box {
  width: 55%;
  height: 55%;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .round-img-box .round-grey-img .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .raven-btn-box-wrap .btn {
  background: linear-gradient(to bottom, #002856, #001833);
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .title-text-box {
  display: flex;
  flex-direction: column;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .title-text-box .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.7rem;
  text-align: center;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .title-text-box .text {
  font-size: 1.5rem;
  text-align: center;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .img-center-box {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .img-center-box .img-box {
  width: 75%;
  height: 75%;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer .wrap-center .img-center-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index .wrap-center-outer-layer-two {
  background-color: rgb(247, 248, 247);
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .no-content-page-found-index-two {
  background-color: #ffffff;
  box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
  border: 0.1rem solid rgba(208, 213, 221, 0.5);
}
.dashboard-layout-index-wrap-no-config .main-wrap-index-box .size-wrap-global-two .logo-no-page-box {
  align-self: center;
  margin-top: 1.5rem;
}
.dashboard-layout-index-wrap-no-config .footer-wrap-index-box {
  min-height: 5rem;
  display: flex;
  justify-content: center;
  padding: 0rem 0rem 1.2rem 0rem;
}
.dashboard-layout-index-wrap-no-config .footer-wrap-index-box .size-wrap-global {
  display: flex;
  justify-content: center;
}
.dashboard-layout-index-wrap-no-config .footer-wrap-index-box .size-wrap-global .power-by-box {
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  padding: 1rem 2rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.dashboard-layout-index-wrap-no-config .footer-wrap-index-box .size-wrap-global .power-by-box .text {
  font-size: 1.15rem;
}
.dashboard-layout-index-wrap-no-config .footer-wrap-index-box .size-wrap-global .power-by-box .img-wrap-box .img-box {
  width: 8rem;
  height: 1.2rem;
}
.dashboard-layout-index-wrap-no-config .footer-wrap-index-box .size-wrap-global .power-by-box .img-wrap-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=NoConfigPage.css.map */