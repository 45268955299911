.badge-reusable-wrap-index {
	// display: flex;
	// justify-content: flex-start;
	// width: 100%;

	.badge-wrap-index {
		padding: 0.65rem 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff6ed;
		cursor: pointer;
		border-radius: 3.5rem;
		width: max-content;
		// min-width: 15rem;

		span {
			text-transform: capitalize;
			font-weight: 500;
			font-size: 1.3rem;
			color: #ea872d !important;
		}
	}

	.pending {
		background-color: #fff6ed;

		span {
			color: #ea872d !important;
		}
	}

	.info {
		background-color: #e9f5ff;

		span {
			color: #476885 !important;
		}
	}

	.failed {
		background-color: #fff5f5;

		span {
			color: #ff0f00 !important;
		}
	}

	.success {
		background-color: #e8fff6;

		span {
			color: #1ACE37 !important;
		}
	}
}
