.no-content-page-index {
  height: calc(100vh - 22rem);
  display: grid;
  place-items: center;
  width: 100%;
  border-radius: 1.6rem;
  padding: 1.2rem;
}
.no-content-page-index .loading-wrap {
  width: 15rem;
  height: 15rem;
}
.no-content-page-index .loading-wrap .img-box {
  width: 100%;
  height: 100%;
}
.no-content-page-index .loading-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.no-content-page-index .wrap-center-outer-layer {
  width: 100%;
  height: 100%;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.no-content-page-index .wrap-center-outer-layer .top-box-wrap {
  flex: 0 0 30%;
  background-image: url("../../assets/empty-bg-lines.png");
  background-position: center;
  background-size: 110%;
  background-repeat: no-repeat;
}
.no-content-page-index .wrap-center-outer-layer .bottom-box-wrap {
  flex: 1;
  border-top-right-radius: 3rem;
  border-top-left-radius: 3rem;
  position: relative;
}
.no-content-page-index .wrap-center-outer-layer .wrap-center {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
}
.no-content-page-index .wrap-center-outer-layer .wrap-center .round-img-box {
  width: 19rem;
  height: 19rem;
  border-radius: 50%;
  padding: 1.4rem;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.no-content-page-index .wrap-center-outer-layer .wrap-center .round-img-box .round-grey-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.no-content-page-index .wrap-center-outer-layer .wrap-center .round-img-box .round-grey-img .img-box {
  width: 55%;
  height: 55%;
}
.no-content-page-index .wrap-center-outer-layer .wrap-center .round-img-box .round-grey-img .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.no-content-page-index .wrap-center-outer-layer .wrap-center .title-text-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.no-content-page-index .wrap-center-outer-layer .wrap-center .title-text-box .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.7rem;
  text-align: center;
}
.no-content-page-index .wrap-center-outer-layer .wrap-center .title-text-box .text {
  font-size: 1.5rem;
  text-align: center;
}
.no-content-page-index .wrap-center-outer-layer .wrap-center .img-center-box {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.no-content-page-index .wrap-center-outer-layer .wrap-center .img-center-box .img-box {
  width: 75%;
  height: 75%;
}
.no-content-page-index .wrap-center-outer-layer .wrap-center .img-center-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=NoContentPage.css.map */