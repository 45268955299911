.img-text-table-wrap-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: max-content;

  .img-wrap-box-trans {
    width: 3rem;
    height: 3rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
    // overflow: hidden;

    .img-box {
        width: 3rem;
        height: 3rem;
      border-radius: 50%;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .img-box-two {
      width: 60%;
      height: 60%;
    }
  }

  .img-wrap-box-trans-two{
    background-color: #e8fff6;
  }

  .text-box {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    
    .text-one {
      font-size: 1.35rem;
      font-weight: 600;
    }

    .text-two {
      font-size: 1.15rem;
    }
  }
}
