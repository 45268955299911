.dashboard-layout-index-wrap-no-config {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  .header-wrap-index-box {
    min-height: 6.5rem;
    // background: linear-gradient(to bottom, #002856, #001833);
    width: 100%;
    position: relative;
    z-index: 9;

    .size-wrap-global {
      height: 100%;
    }
  }

  .main-wrap-index-box {
    flex: 1;
    overflow-y: scroll;
    padding: 2rem 0rem 1rem 0rem;
    // background-color: red;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    position: relative;
    z-index: 8;

    &::-webkit-scrollbar {
      display: none;
    }

    .size-wrap-global-two {
      // background-color: blue;
      height: 100%;
      display: flex;
      flex-direction: column;

      .no-content-page-found-index {
        height: calc(100vh - 22rem);
        display: grid;
        place-items: center;
        width: 100%;
        border-radius: 1.6rem;
        // background-color: red;
        padding: 1.2rem;
        margin: auto 0rem;

        .loading-wrap {
          width: 20rem;
          height: 20rem;

          .img-box {
            width: 100%;
            height: 100%;

            .img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }

        .wrap-center-outer-layer {
          width: 100%;
          height: 100%;
          border-radius: 1.6rem;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          .top-box-wrap {
            flex: 0 0 50%;
            background-image: url("../../assets/empty-bg-lines.png");
            background-position: center;
            background-size: 110%;
            background-repeat: no-repeat;
            position: relative;

            &::after {
              position: absolute;
              bottom: 0%;
              left: 50%;
              transform: translate(-40%, -30%);
              content: "";
              width: 40%;
              height: 100%;
              //   background-color: red;
              background-image: url("../../assets/No_Terminal_png 1no-config-center-img.png");
              background-position: center bottom;
              background-size: contain;
              background-repeat: no-repeat;
              //   display: none;

              @media only screen and (max-width: 48em) {
                width: 100%;
                height: 100%;
              }
            }
          }

          .bottom-box-wrap {
            flex: 1;
            border-top-right-radius: 3rem;
            border-top-left-radius: 3rem;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
          }

          .wrap-center {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            align-items: center;

            .round-img-box {
              width: 19rem;
              height: 19rem;
              border-radius: 50%;
              padding: 1.4rem;
              // background-color: red;
              position: absolute;
              top: 0%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              display: none;

              .round-grey-img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: grid;
                place-items: center;

                .img-box {
                  width: 55%;
                  height: 55%;

                  .img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            .raven-btn-box-wrap {
              .btn {
                background: linear-gradient(to bottom, #002856, #001833);
              }
            }

            .title-text-box {
              display: flex;
              flex-direction: column;
              // position: absolute;
              // top: 50%;
              // left: 50%;
              // transform: translateX(-50%) translateY(-50%);

              .title {
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 0.7rem;
                text-align: center;
              }

              .text {
                font-size: 1.5rem;
                text-align: center;
              }
            }

            .img-center-box {
              width: 20rem;
              height: 20rem;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: flex-end;

              .img-box {
                width: 75%;
                height: 75%;

                .img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

        .wrap-center-outer-layer-two {
          background-color: rgba(247, 248, 247, 1);
        }
      }

      .no-content-page-found-index-two {
        background-color: #ffffff;
        box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
        border: 0.1rem solid rgba(208, 213, 221, 0.5);
      }

      .logo-no-page-box {
        align-self: center;
        margin-top: 1.5rem;
      }
    }

    // .child {
    //   height: 250rem;
    //   background-color: green;
    //   width: 100%;
    // }
  }

  .footer-wrap-index-box {
    min-height: 5rem;
    display: flex;
    justify-content: center;
    // background-color: red;
    padding: 0rem 0rem 1.2rem 0rem;

    .size-wrap-global {
      display: flex;
      justify-content: center;

      .power-by-box {
        width: max-content;
        cursor: pointer;
        padding: 1rem 2rem;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        .text {
          font-size: 1.15rem;
        }

        .img-wrap-box {
          .img-box {
            width: 8rem;
            height: 1.2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  // .size-wrap-global{

  // }
}
