.pagination-fragment-index-wrap {
  display: flex;
  width: -moz-max-content;
  width: max-content;
  align-items: center;
  justify-content: flex-end;
  gap: 0.4rem;
  padding: 0rem 1.5rem;
  border-radius: 1.2rem;
  align-self: stretch;
}
.pagination-fragment-index-wrap .left-icon-box,
.pagination-fragment-index-wrap .right-icon-box {
  border-radius: 0.4rem;
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.pagination-fragment-index-wrap .left-icon-box .img-box,
.pagination-fragment-index-wrap .right-icon-box .img-box {
  width: 100%;
  height: 100%;
}
.pagination-fragment-index-wrap .left-icon-box .img-box .img,
.pagination-fragment-index-wrap .right-icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.pagination-fragment-index-wrap .left-icon-box:hover {
  transform: translateX(-0.1rem);
}
.pagination-fragment-index-wrap .right-icon-box:hover {
  transform: translateX(0.1rem);
}
.pagination-fragment-index-wrap .disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.pagination-fragment-index-wrap .disabled:hover {
  transform: unset;
}/*# sourceMappingURL=PaginationFragment.css.map */