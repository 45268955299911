.filter-fragment-index-wrap-outer {
  position: relative;
  height: 100%;
}
.filter-fragment-index-wrap-outer .filter-fragment-index-wrap {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 0.8rem 2rem;
  border-radius: 0.8rem;
  height: 100%;
  align-self: stretch;
  cursor: pointer;
}
.filter-fragment-index-wrap-outer .filter-fragment-index-wrap .text {
  font-size: 1.35rem;
  font-weight: 500;
  text-transform: capitalize;
}
.filter-fragment-index-wrap-outer .filter-fragment-index-wrap .img-box {
  width: 2rem;
  height: 2rem;
  transition: transform 0.3s ease-in-out;
}
.filter-fragment-index-wrap-outer .filter-fragment-index-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.filter-fragment-index-wrap-outer .children-drop-box {
  border-radius: 1.2rem;
  height: -moz-max-content;
  height: max-content;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: absolute;
  top: 110%;
  right: 0%;
  animation: moveDownSettingDrop 0.3s ease-in-out backwards;
}
.filter-fragment-index-wrap-outer .children-drop-box::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 48em) {
  .filter-fragment-index-wrap-outer .children-drop-box {
    left: 0%;
    right: unset;
  }
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down {
  padding: 2rem;
  transform-origin: top right;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  z-index: 100;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down--open {
  transform: scale(1);
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap {
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 21rem;
  max-width: 45rem;
  padding-bottom: 2rem;
  max-height: 400px;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 48em) {
  .filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap {
    min-width: unset;
    width: 25rem;
    max-height: unset;
    padding-bottom: unset;
  }
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  justify-content: space-between;
  padding: 1.8rem;
  border-bottom: 1px solid #eee;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__header p {
  font-size: 1.4rem;
  color: #000;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__header p:last-child {
  color: var(--error-100, #ff0f00);
  font-weight: 500;
  cursor: pointer;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__date-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  justify-content: space-between;
  padding: 1.8rem;
  width: 100%;
  cursor: pointer;
  flex: 1;
  border-bottom: 1px solid #eee;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  align-self: stretch;
  background: var(--base-background, #f9f9f9);
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s;
  position: relative;
  animation-timing-function: ease-in-out;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__item.active {
  max-height: 500rem;
  transition: max-height 3s;
  animation-timing-function: ease, step-start, cubic-bezier(0.1, 0.7, 1, 0.1);
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__item .span {
  display: flex;
  padding: 1.2rem 2.4rem;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-bottom: 0.4px solid var(--ravengreyshade-50, #e5e6e6);
  gap: 1.2rem;
  cursor: pointer;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__item .span p {
  color: var(--raven-black-600, #646464);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__item .custom-date {
  flex-direction: column;
  width: 100%;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__item .custom-date__select {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__item .custom-date__form-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 1.6rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s cubic-bezier(0.445, 0.05, 0.25, 0.94);
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__item .custom-date__form-wrap .form-group__deep-green-light .form-input {
  background: var(--raven-black-100, #eee);
  border: none;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__item .custom-date__form-wrap.show {
  max-height: 40rem;
  transition: max-height 1s cubic-bezier(0.445, 0.05, 0.24, 0.94);
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__custom-date-range {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__custom-date-range h5 {
  font-weight: 600;
  font-size: 1.4rem;
  color: #014345;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__custom-date-range .custom-date-range__grouped-form {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1.2rem;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__custom-date-range .drop-down__action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 5.2rem;
  padding: 2.3rem 0rem 0.3rem 0rem;
  border-top: 0.7px solid #e5e6e6;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__custom-date-range .drop-down__action-btns button {
  width: 50%;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .drop-down__custom-date-range .drop-down__action-btns button:first-child {
  background: #e9f5ff;
  color: #014345;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .tag-close-icon {
  transform: translateX(0);
  transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  right: 2rem;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .tag-close-icon.hide {
  transform: translateX(50px);
  display: flex;
  right: 0;
  transition: transform 1.5s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__tags {
  display: flex;
  align-items: center;
  border-radius: 72px;
  background: var(--raven-black-100, #eee);
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
  padding: 0.8rem 1.2rem;
  gap: 0.8rem;
  transform: translateX(250px);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__tags.show {
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__tags p {
  color: #000;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__tags figure {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__tags figure svg {
  width: 100%;
  height: 100%;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__apply-filter-btn {
  display: flex;
  width: 100%;
  padding: 2rem;
  position: absolute;
  bottom: 0;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .filter-export__drop-down-wrap .dropdown__apply-filter-btn button {
  width: 100%;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__switch {
  display: flex;
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  gap: 1.2rem;
  border-bottom: 0.8px solid #e5e6e6;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__switch span {
  gap: 0.4rem;
  padding-bottom: 1.6rem;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__switch span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: grid;
  place-items: center;
  padding: 1.2rem 1.6rem;
  border-radius: 8px;
  color: #1b1b1b;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__switch span.active {
  border-bottom: 4px solid #014345;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__switch span.active p {
  color: #014345;
  font-weight: 600;
  background: #e9f5ff;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__custom-date-range {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__custom-date-range h5 {
  font-weight: 600;
  font-size: 1.4rem;
  color: #014345;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__custom-date-range .custom-date-range__grouped-form {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1.2rem;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 5.2rem;
  padding: 2.3rem 0rem 0.3rem 0rem;
  border-top: 0.7px solid #e5e6e6;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button {
  width: 50%;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button:first-child {
  background: #e9f5ff;
  color: #014345;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down-closer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  display: none;
  visibility: none;
}
.filter-fragment-index-wrap-outer .children-drop-box .filter-export__drop-down-closer--opened {
  display: unset;
  visibility: unset;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=FilterFragment.css.map */