.export-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
}
.export-modal__title {
  display: flex;
  flex-direction: column;
}
.export-modal__title h5 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 32px */
}
.export-modal__title p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.export-modal__export-as {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}
.export-modal__export-as--selector-wrap {
  display: flex;
  gap: 1.6rem;
  margin-bottom: 2.8rem;
}
.export-modal__export-as--selector-wrap .selector-wrap__item {
  display: flex;
  height: 6rem;
  padding: 0.8rem 1.2rem;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 1.2rem;
  border: 1px solid var(--raven-black-100, #eee);
  background: var(--base-white, #fff);
  margin-top: 1.2rem;
  cursor: pointer;
}
.export-modal__export-as--selector-wrap .selector-wrap__item.selected {
  border: 2px solid var(--primary-darkgreen-100, #014345);
  background: var(--raven-black-50, #f7f7f7);
}
.export-modal__days-selector {
  display: flex;
  flex-direction: column;
}
.export-modal__days-selector .days-selector__item-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.2rem;
  padding: 2rem 0;
}
.export-modal__days-selector .days-selector__item-wrap .days-selector__item {
  display: flex;
  padding: 0.8rem 1.2rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 72px;
  cursor: pointer;
  background: var(--raven-black-50, #f7f7f7);
}
.export-modal__days-selector .days-selector__item-wrap .days-selector__item.selected {
  border-radius: 72px;
  border: 1.4px solid var(--primary-darkgreen-100, #014345);
  background: var(--raven-black-100, #eee);
}
.export-modal__days-selector .days-selector__item-wrap .days-selector__item.selected figure svg path {
  stroke: #014345;
}
.export-modal__days-selector .days-selector__item-wrap .days-selector__item.selected figure {
  border-radius: 50rem;
  width: -moz-min-content;
  width: min-content;
  height: -moz-min-content;
  height: min-content;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.export-modal__days-selector .days-selector__item-wrap .days-selector__item.selected p {
  color: var(--primary-darkgreen-100, #014345);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 16.8px */
}
.export-modal__days-selector .days-selector__item-wrap .days-selector__item p {
  color: var(--raven-black-500, #8b8b8b);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
.export-modal__custom-date {
  display: flex;
  flex-direction: row;
  gap: 2.4rem;
  align-items: center;
  margin-bottom: 4.9rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
.export-modal__custom-date.show {
  max-height: 400rem;
  transition: max-height 0.3s ease-in-out;
}/*# sourceMappingURL=ExportModal.css.map */