.img-text-table-wrap-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: -moz-max-content;
  width: max-content;
}
.img-text-table-wrap-box .img-wrap-box-trans {
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
}
.img-text-table-wrap-box .img-wrap-box-trans .img-box {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.img-text-table-wrap-box .img-wrap-box-trans .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.img-text-table-wrap-box .img-wrap-box-trans .img-box-two {
  width: 60%;
  height: 60%;
}
.img-text-table-wrap-box .img-wrap-box-trans-two {
  background-color: #e8fff6;
}
.img-text-table-wrap-box .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.img-text-table-wrap-box .text-box .text-one {
  font-size: 1.35rem;
  font-weight: 600;
}
.img-text-table-wrap-box .text-box .text-two {
  font-size: 1.15rem;
}/*# sourceMappingURL=ImageTextFragment.css.map */