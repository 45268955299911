.transaction-detail-modal-index-wrap {
  min-height: 35rem;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 600;
    font-size: 1.65rem;
  }

  .switch-list-box {
    display: flex;
    align-items: center;
    padding: 0.9rem 1rem;
    border-radius: 0.8rem;
    gap: 2rem;
    margin: 1.5rem 0rem;

    .switch-item {
      padding: 0.8rem 1.2rem;
      cursor: pointer;
      border-radius: 1.2rem;

      span {
        font-size: 1.35rem;
      }
    }

    .switch-item-active {
      cursor: default;
      span {
        color: #002856 !important;
        font-weight: 600;
      }
    }
  }

  .content-basic-device-wrap {
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;
    // gap: 2rem;

    .label-value-box {
      display: flex;
      align-items: center;
      padding: 1.5rem 1.2rem;

      .label {
        flex: 0 0 25%;
      }

      .value {
        flex: 1;

        span{
            font-size: 1.3rem;
        }
      }
    }

    & > *:last-child {
      //   padding: 1rem;
      border-bottom: unset !important;
    }
  }
}
