@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_extra_light_italic-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_extra_light_italic-webfont.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_extra_light-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_extra_light-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_light_italic-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_light_italic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_light-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_medium_italic-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_medium_italic-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_medium-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_regular_italic-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_regular_italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_regular-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_semibold_italic-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_semibold_italic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_semibold-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_thin_italic-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_thin_italic-webfont.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_thin-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_thin-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_black_italic-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_black_italic-webfont.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_black-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_black-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_bold_italic-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_bold_italic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_bold-webfont.woff2") format("woff2"),
    url("./fonts/br_firma_bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

// Root colors
:root {
  // base starts here ----
  --base-white: #ffffff;
  --base-black: #020202;
  --base-background-white: #f9f9f9;
  --base-background-black: #676767;

  // border color start here ----
  --border-green: #0b8376;
  --border-grey-dark: #676767;
  --border-grey-light: #cccccc;
  --border-light: #f7f8f7;

  // primary start here ------
  --primary-green: #0b8376;
  --primary-deep-green: #014345;
  --primary-blue: #476885;
  --primary-orange: #ea872d;
  --primary-purple: rgba(117, 90, 226, 1);
  --primary-black: #333333;

  // accent starts here -------

  --fade-purple-bg: rgba(241, 238, 252, 1);
  --icon-fade-bg: rgba(180, 164, 252, 1);

  // --- app colors here ---------
  --primary-base-color: #002856;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 55.5% !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  @media only screen and (max-width: 75em) {
    font-size: 52% !important;
  }
}

body {
  font-size: 1.4rem;
  font-family: "br_firma", sans-serif !important;
  font-weight: 400;
  /* overflow-x: hidden !important; */
  position: relative;
  width: 100vw;
  overscroll-behavior: none;
  //   color: var(--text-color);
  overflow-y: scroll;
  overflow-x: hidden;
  //   background-color: red;
  color: #333333;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  // @media only screen and (min-width: 105em) {
  //   width: 160rem !important;
  // }
}

.blurr-bg-box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(1rem);
    z-index: 3;
    background-color: rgba(2, 2, 2, 0.35);
    cursor: pointer;
  }

  & > * {
    position: absolute;
    z-index: 5 !important;
    top: 15rem;
    right: 13rem;
  }

  .message-wrap-box {
    animation: moveDownSettingDrop 0.5s ease-in-out backwards;
    position: absolute;
    // top: 110%;
    z-index: 10;
    width: 55rem;
    height: 15rem;
    border-radius: 1.6rem;
    display: flex;
    overflow: hidden;

    @media only screen and (max-width: 48em) {
      min-width: unset;
      width: 80vw;
      flex-direction: column;
      gap: 1rem;
      height: unset;
      padding-bottom: 2rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: unset;
    }

    .left-box {
      flex: 0 0 35%;
      background-position: left;
      background-size: cover;
      background-repeat: no-repeat;

      @media only screen and (max-width: 48em) {
        flex: unset;
        width: 100%;
        min-height: 20rem;
      }
    }

    .right-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0rem 2rem;
      justify-content: center;

      @media only screen and (max-width: 48em) {
        flex: unset;
        width: 100%;
      }

      .title {
        font-weight: 700;
        font-size: 1.7rem;
        margin-bottom: 1rem;

        @media only screen and (max-width: 48em) {
          font-size: 2.2rem;
        }
      }

      .text {
        font-size: 1.2rem;

        @media only screen and (max-width: 48em) {
          font-size: 1.5rem;
        }

        span {
          font-weight: 700;
          color: var(--primary-purple) !important;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 48em) {
  .flatpickr-input {
    height: 5rem !important;
  }

  .form-modal-auth .form-group .pin-group .pin_field_group {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1.5rem;
  }

  .form-modal-auth .form-group .pin-group .pin_field_group input:focus {
    font-size: 16px;
  }

  .pin-group .pin_field_group {
    display: grid;
    justify-content: space-between;
    width: 90%;
    // margin: 0rem auto;
  }

  .pin-group .pin_field_group input:focus {
    font-size: 16px;
  }
}

.table-business .table-body .table-row .table-data span {
  font-weight: 600;
}

.modal-parent-wrap {
  .content-wrap {
    width: 45rem;
    // background-color: red;
  }
}

.raven-btn {
  font-family: "br_firma", sans-serif !important;
}

.box-card {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  gap: 0.5rem;

  .name {
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 2.5rem;
  }

  .text {
    font-size: 1.2rem;
  }
}

.form-group {
  .input-submit {
    @media only screen and (max-width: 42em) {
      height: 5rem !important;
    }
  }
}

.recaptcha-wrapper {
  // margin-top: 2rem;
  // visibility: collapse;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5000;
}

.hover-white-class {
  transition: all 0.3s ease-in-out;
  position: relative;

  & > * {
    position: relative;
    z-index: 3;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    // visibility: hidden;
  }

  // &:hover {
  //   // background: #ffffff ;
  //   // &::after {
  //   //   // visibility: visible;
  //   // }
  // }
}

.check-box-wrap .input-check:checked ~ .label-check__purple-light {
  background-color: var(--primary-purple) !important;
}

.check-box-wrap .label-check__purple-light {
  border: 0.15rem solid var(--primary-purple) !important;
}

.per-page-component-wrap {
  .form-group {
    .react-select-class {
      min-width: unset;
      // width: 7rem;
      // max-width: 7rem;
      & > *:nth-child(3) {
        // input{
        //   // width: 7rem;
        // }
        & > *:first-child {
          padding: unset;
          padding-left: 8px;
        }

        & > *:nth-child(2) {
          & > *:nth-child(2) {
            padding-left: 2px;
          }
        }
      }
    }
  }
}

.select-option-class {
  position: relative;
  cursor: pointer;
  // background-color: #ea872d;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 0.8rem;
    border: 0.15rem solid #020202;
    visibility: hidden;
  }

  .check-box {
    position: absolute;
    left: unset;
    top: 1.2rem;
    right: 1.2em !important;
    display: grid;
    place-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #020202;
    visibility: hidden;

    .icon {
      width: 0.6rem;
      height: 0.6rem;
      fill: #ffffff;
    }
  }
}

.select-option-class-active {
  &::before {
    visibility: visible;
  }

  .check-box {
    visibility: visible;
  }
}

.label-class-span {
  //   color: #020202;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.5;
}

.label-class-span-active {
  opacity: 1;
}

//   .form-group__black-light .form-input{
//     border: .1rem solid #cccccc;
//   }

.auth-pin-modal-wrap .raven-modal-content-wrap .close-box .img-box .img {
  fill: #020202;
  stroke: #020202;
}

.animate-move-up-class {
  animation: moveUpAnime 0.5s backwards ease-in-out;
}

@keyframes moveUpAnime {
  0% {
    transform: translateY(2%);
    opacity: 0.7;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.mobile-table-box {
  @media only screen and (min-width: 48em) {
    display: none;
  }
  // min-height: 20rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > *:not(:last-child) {
    border-right: unset;
    border-top: unset;
    border-left: unset;
    padding-bottom: 1.5rem;
  }

  & > *:last-child {
    border: unset;
  }
}

.space-bottom {
  height: 10rem;

  @media only screen and (max-width: 55em) {
    display: none;
  }
}

.form-group__black-light .input-submit {
  font-family: "br_firma", sans-serif !important;
}

// .no-content-wrapper-page-box .spinner-wrap {
//   display: none !important;
// }

.dayContainer .prevMonthDay,
.dayContainer .nextMonthDay {
  opacity: 0.05 !important;
}

.raven-btn svg {
  // display: none;
  margin-left: unset !important;
}

.money-received-index-wrap-modal {
  .wrap {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2.5rem;

    .img-wrap {
      align-self: flex-start;
      margin-bottom: 1.5rem;

      .img-box {
        width: 4rem;
        height: 4rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .title {
      font-size: 2rem;
      font-weight: 600;
    }

    .text {
      font-size: 1.55rem;
    }
  }
}

.modal-parent-wrap .raven-modal-content-wrap {
  @media only screen and (max-width: 48em) {
    border-radius: 0.8rem !important;
    width: 95vw !important;
  }
}

.session-expired-wrap {
  // display: none;

  input {
    border: none !important;
  }

  .two-btn-box {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: flex-end;
  }
}

.form-group__green-light .pin-group .pin_field:focus {
  border-color: var(--primary-base-color) !important;
}

.form-group__green-light .input-submit,
.form-group__deep-green-light .input-submit,
.btn-green-light {
  background-color: var(--primary-base-color) !important;
  // background-color: unset;
}

.content-global-size {
  width: 70%;
  margin: 0rem auto;
  // background-color: blue;

  @media only screen and (max-width: 50em) {
    width: 90%;
  }
}
.delete-modal-reuse-wrap .raven-modal-content-wrap .container-wrap {
  margin-top: unset !important;
}

.fade-purple-bg {
  background-color: var(--fade-purple-bg) !important;
}

.form-group__purple-dark .input-submit {
  background-color: var(--primary-purple) !important;
}

.btn-purple-light {
  background-color: var(--primary-purple) !important;
}

.card_number {
  input {
    width: 100%;
  }
}

.size-wrap-global {
  width: 80%;
  margin: 0rem auto;

  @media only screen and (max-width: 58em) {
    width: 95%;
  }
}

// .scrollable-content {
//   width: 100%;
//   transform: translateY(100%) !important;
//   transition: transform 0.3s ease-in-out;
//   position: absolute;
//   top: 100%;

// }

// .transformed-content {
//   transform: translateY(0);
//   position: fixed;
//   top: unset;
// }

.btn-reset {
  background-color: unset;
  border: unset;
  color: inherit;
  font: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.purple-accent-box {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.7rem 2rem;
  border-radius: 3rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: var(--primary-purple);
}

.reset-border {
  border: none;
}

// .mobile-table-box {
//   @media only screen and (min-width: 48em) {
//     display: none;
//   }
// }

.mobile-custom-title-box-index-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  .text {
    font-size: 1.75rem;
    font-weight: 600;
  }

  .icon-wrap-box {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 1rem;
    border-width: 0.15rem;

    .img-box {
      width: 55%;
      height: 55%;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.mobile-table-box {
  @media only screen and (min-width: 48em) {
    display: none;
  }
  // min-height: 20rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > *:not(:last-child) {
    border-right: unset;
    border-top: unset;
    border-left: unset;
    padding-bottom: 1.5rem;
  }

  & > *:last-child {
    border: unset;
  }
}

.raven-btn {
  background-color: var(--primary-base-color) !important;
}

.form-modal-auth-login .pin-group .pin_field_group {
  @media only screen and (max-width: 48em) {
    width: 100% !important;
    grid-gap: 1rem !important;
  }
}

.form-group__deep-green-light .form-input {
  color: unset !important;
}

.form-group__green-light .form-input:focus {
  &:valid {
    border-color: var(--primary-base-color) !important;
  }
}

.export-table-content-box {
  height: 100% !important;
}

.filter-export-pagination-box {
  flex: 1;
  justify-content: flex-end;

  @media only screen and (max-width: 48em) {
    gap: unset;
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(5rem, max-content));
    justify-content: flex-start;
    grid-gap: 2rem;

    & > * {
      width: max-content !important;
    }
  }
}

.spin .spintula-arc-deep-green-light,
.spin .spintula-arc-error-light,
.spin .spintula-arc-green-light {
  border-bottom-color: #ffffff !important;
}

.error-light-two {
  background-color: #ff0f00 !important;
}


.flier-modal-wrap-box {
	height: 100%;
	display: flex;
	flex-direction: column;
	// background-color: red;
	// width: 75rem;

	.flier_download_btn {
		width: 100%;
		margin-top: auto;
		border-width: 0.2rem;
		border-radius: 0.8rem;

		& > * {
			width: 100%;

			background-color: #f7f7f7 !important;

			color: #020202;
			font-weight: 600;
			// border-color: #1ace37 !important;
		}
	}
}

.flier-modal-btn-box .form-group__deep-green-light .input-submit {
	background-color: #e8fff6;

	color: #1ace37;
	font-weight: 600;
	border-color: #1ace37 !important;
}

.flier-modal-btn-box {
	.raven-modal-content-wrap {
		width: 50rem !important;
	}
}

.logout-btn-classmaen{
  background-color: rgba(255, 15, 0, 1) !important;
}  