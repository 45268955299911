.title-text-index-box-wrap {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-direction: row;
  // display: none;
  width: 100%;
  margin-bottom: 2rem;
  // background-color: red;
  position: relative;
  z-index: 20;

  @media only screen and (max-width: 48em) {
    // flex-direction: column;
    gap: 1rem;
  }

  .back-icon-box {
    width: 3.7rem;
    height: 3.7rem;
    background: linear-gradient(to bottom, #002856, #001833);
    display: grid;
    place-items: center;
    cursor: pointer;
    border-radius: 50%;
    margin-right: 1rem;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateX(-0.25rem);
    }

    .img-box {
      width: 50%;
      height: 50%;

      // @media only screen and (max-width: 48em) {
      //   width: 2.5rem;
      //   height: 2.5rem;
      // }

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .title-text-box {
    display: flex;
    flex-direction: column;
    width: max-content;
    // background-color: red;
    flex: 1;

    .title {
      font-size: 2.2rem;
      font-weight: 700;
      margin-bottom: 0.45rem;
      line-height: 2.5rem;

      @media only screen and (max-width: 48em) {
        font-size: 1.8rem;
      }
    }

    .text {
      font-size: 1.35rem;
      text-align: center;
      text-wrap: nowrap;

      @media only screen and (max-width: 48em) {
        text-align: left;
        text-wrap: unset;
      }
    }
  }

  .children-box {
    width: 100%;
    display: flex;
    @media only screen and (max-width: 48em) {
      width: max-content;
      margin-left: auto;
    }
    .btn-insight {
      //   background-color: rgba(250, 250, 255, 1);
      border-color: var(--primary-purple);
      border-width: 0.1rem;

      @media only screen and (max-width: 48em) {
        padding: 0.8rem 1rem !important;
      }

      .img-box {
        width: 1.8rem;
        height: 1.8rem;

        @media only screen and (max-width: 48em) {
          width: 2.5rem;
          height: 2.5rem;
        }

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        color: var(--primary-purple);

        @media only screen and (max-width: 48em) {
          display: none;
        }
      }
    }

    .settings-icon-drop-box-wrap {
      display: flex;
      margin-left: auto;
      position: relative;
      z-index: 10;

      .drop-down-icon-box {
        animation: moveDownSettingDrop 0.5s ease-in-out backwards;
        position: absolute;
        top: 100%;
        right: 0%;
        padding: 2rem 1.5rem;
        width: max-content;
        border-radius: 1.6rem;
        border-width: 0.15rem;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;

        .icon-text-box {
          display: flex;
          align-items: center;
          gap: 1rem;
          cursor: pointer;

          .text{
            font-size: 1.55rem;
          }

          .icon-img-box {
            .img-box {
              width: 2rem;
              height: 2rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }

      .icon-box-wrap {
        width: 3.5rem;
        height: 3.5rem;
        display: grid;
        place-items: center;
        border-radius: 50%;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        border-width: 0.15rem;

        .img-box {
          width: 55%;
          height: 55%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .filter-by-duration-box {
      display: flex;
      align-items: center;
      // gap: 1rem;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: 0.8rem;
      margin-left: auto;
      cursor: pointer;

      .text {
        font-weight: 600;
      }

      .img-wrap-filter {
        .img-box {
          width: 2.5rem;
          height: 2.5rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .react-select-class > *:nth-child(3) {
        width: 12rem;
        border: unset !important;
        .css-tj5bde-Svg {
          display: none !important;
        }
        .css-1u9des2-indicatorSeparator,
        .css-1xc3v61-indicatorContainer {
          display: none !important;
        }
      }

      .react-select-class > *:nth-child(4) {
        width: 16rem;
        left: unset !important;
        right: -3rem !important;
      }
    }
  }
}


@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}