

.export-fragment-index-wrap{
    display: flex;
    align-items: center;
    gap: .7rem;
    padding: .8rem 2rem;
    border-radius: .8rem;
    height: 100%;
    align-self: stretch;
    cursor: pointer;

    &:hover{
        .img-box{
            transform: translateY(.2rem);
        }
    }

    .text{
        font-size: 1.35rem;
        font-weight: 500;
        text-transform: capitalize;
    }

    .img-box{ 
        width: 2rem;
        height: 2rem;
        transition: transform .3s ease-in-out;

        .img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}