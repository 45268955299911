.per-page-wrapper-class-index {
  // width: 8rem;
  max-width: 10rem;
  //   height: 3rem;
  //   min-height: unset;
  //   max-height: unset;
  //   align-self: center;

  //   .form-group__deep-green-light {
  .react-select-class > *:nth-child(3) {
    display: flex;
    flex-direction: row;
    background-color: unset;
  }

  // .react-select-class > *:nth-child(4){

  // }
  //   }

  .css-1fdsijx-ValueContainer {
    padding-right: unset !important;
  }
}

// .per-page-wrapper-class-index .react-select-class > *:nth-child(3){

// }
