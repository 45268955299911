.auth-layout-index-wrap {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 58em) {
    width: 100%;
    height: 100%;
  }

  .header-wrap-index-box {
    min-height: 6.5rem;
    //   background: linear-gradient(to bottom, #002856, #001833);
    width: max-content;
    align-self: center;
    margin: 3rem 0rem;

    .logo-no-page-box {
      align-self: center;

      .img-box {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        display: grid;
        place-items: center;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }
    }
  }

  .main-wrap-index-box {
    flex: 1;
    overflow-y: scroll;
    padding: 2rem;
    // background-color: red;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    display: grid;
    place-items: center;
    align-items: flex-start;

    @media only screen and (max-width: 58em) {
      // background-color: red;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .content-wrap {
      @media only screen and (max-width: 58em) {
        width: 100%;
      }
    }
  }

  .footer-wrap-index-box {
    min-height: 5rem;
    display: flex;
    justify-content: center;
    // background-color: red;
    padding: 0rem 0rem 1.2rem 0rem;

    .size-wrap-global {
      display: flex;
      justify-content: center;

      .power-by-box {
        width: max-content;
        cursor: pointer;
        padding: 1rem 2rem;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        // gap: 1rem;
        background-color: #ffffff;
        border: 0.15rem solid rgba(208, 213, 221, 0.5);

        .text {
          font-size: 1.15rem;
        }

        .img-wrap-box {
          .img-box {
            width: 8rem;
            height: 1.2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  // .size-wrap-global{

  // }
}
