.header-nav-index-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 48em) {
  .header-nav-index-wrap .mobile-slide-bar-toggle-box {
    display: none;
  }
}
.header-nav-index-wrap .mobile-slide-bar-toggle-box .icon {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  color: #ffffff;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  transform: translateX(-100%);
}
@media only screen and (min-width: 48em) {
  .header-nav-index-wrap .side-nav-mobile-wrap-box {
    display: none;
  }
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .inner-wrap {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #020202;
  opacity: 0.4;
  z-index: 2;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box {
  width: 27rem;
  height: 100%;
  position: relative;
  z-index: 3;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 3rem 0rem 5rem 2.5rem;
  padding-right: unset;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .logo-text-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .logo-text-box .logo-box .img-box {
  width: 3.5rem !important;
  height: 3.5rem !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .logo-text-box .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain !important;
     object-fit: contain !important;
  border-radius: 50%;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .logo-text-box .logo-text {
  padding-left: 1.3rem;
  border-left: 0.15rem solid rgba(255, 255, 255, 0.6);
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .logo-text-box .logo-text .text {
  color: #ffffff;
  font-size: 1.3rem;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .logo-text-box .logo-box {
  margin-left: -1.3rem;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .logo-text-box .logo-box .img-box {
  width: 10rem;
  height: 2rem;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .logo-text-box .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .link-list-box {
  margin: 0rem auto;
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media only screen and (max-width: 48em) {
  .header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .link-list-box {
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
    align-items: stretch;
    width: 100%;
  }
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .link-list-box .link-item {
  padding: 1.5rem 0rem;
  cursor: pointer;
  position: relative;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .link-list-box .link-item::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0% !important;
  height: 100%;
  width: 0.6rem;
  background-color: #ffffff;
  transform: translateY(-50%);
  visibility: hidden;
  left: unset;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .link-list-box .link-item::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(269.91deg, rgba(255, 255, 255, 0.1) 22.13%, rgba(255, 255, 255, 0) 96.37%);
  transform: translateY(-50%);
  visibility: hidden;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .link-list-box .link-item .link {
  color: #ffffff;
  font-size: 1.3rem;
  position: relative;
  z-index: 2;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .link-list-box .link-item-active {
  cursor: default;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .link-list-box .link-item-active .link {
  font-weight: 700;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .link-list-box .link-item-active::after, .header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .link-list-box .link-item-active::before {
  visibility: visible;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .theme-wrap-box {
  display: flex;
  align-items: center;
  background-color: rgba(247, 248, 247, 0.12);
  padding: 0.8rem 1.5rem;
  gap: 1rem;
  border-radius: 0.8rem;
}
@media only screen and (max-width: 48em) {
  .header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .theme-wrap-box {
    display: flex;
    width: -moz-max-content;
    width: max-content;
    margin-top: auto;
    align-self: center;
    margin-bottom: 5rem;
  }
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .theme-wrap-box .theme-nav-box {
  width: 5rem;
  height: 4.5rem;
  display: grid;
  place-items: center;
  position: relative;
  cursor: pointer;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .theme-wrap-box .theme-nav-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.23);
  border-radius: 0.8rem;
  visibility: hidden;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .theme-wrap-box .theme-nav-box .img-box {
  width: 65%;
  height: 65%;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .theme-wrap-box .theme-nav-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .theme-wrap-box .theme-nav-box-active {
  cursor: default;
}
.header-nav-index-wrap .side-nav-mobile-wrap-box .side-list-box .theme-wrap-box .theme-nav-box-active::after {
  visibility: visible;
}
.header-nav-index-wrap .logo-text-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.header-nav-index-wrap .logo-text-box .logo-text {
  padding-left: 1.3rem;
  border-left: 0.15rem solid rgba(255, 255, 255, 0.6);
}
.header-nav-index-wrap .logo-text-box .logo-text .text {
  color: #ffffff;
  font-size: 1.3rem;
}
.header-nav-index-wrap .logo-text-box .logo-box .img-box {
  width: 10rem;
  height: 2rem;
}
.header-nav-index-wrap .logo-text-box .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header-nav-index-wrap .logo-text-box .logo-box {
  margin-right: 1rem;
}
.header-nav-index-wrap .logo-text-box .logo-box .img-box {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.header-nav-index-wrap .logo-text-box .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50%;
}
.header-nav-index-wrap .link-list-box {
  margin: 0rem auto;
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media only screen and (max-width: 48em) {
  .header-nav-index-wrap .link-list-box {
    display: none;
  }
}
.header-nav-index-wrap .link-list-box .link-item {
  padding: 1.3rem 2.5rem;
  cursor: pointer;
  position: relative;
}
.header-nav-index-wrap .link-list-box .link-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 0.8rem;
  visibility: hidden;
}
.header-nav-index-wrap .link-list-box .link-item .link {
  color: #ffffff;
  font-size: 1.3rem;
  position: relative;
  z-index: 2;
}
.header-nav-index-wrap .link-list-box .link-item-active {
  cursor: default;
}
.header-nav-index-wrap .link-list-box .link-item-active .link {
  font-weight: 600;
}
.header-nav-index-wrap .link-list-box .link-item-active::after {
  visibility: visible;
}
.header-nav-index-wrap .theme-wrap-box {
  display: flex;
  align-items: center;
  background-color: rgba(247, 248, 247, 0.12);
  padding: 0.7rem 1.2rem;
  gap: 1rem;
  border-radius: 1.6rem;
}
@media only screen and (max-width: 48em) {
  .header-nav-index-wrap .theme-wrap-box {
    display: none;
  }
}
.header-nav-index-wrap .theme-wrap-box .theme-nav-box {
  width: 3.3rem;
  height: 3.3rem;
  display: grid;
  place-items: center;
  position: relative;
  cursor: pointer;
}
.header-nav-index-wrap .theme-wrap-box .theme-nav-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.23);
  border-radius: 0.8rem;
  visibility: hidden;
}
.header-nav-index-wrap .theme-wrap-box .theme-nav-box .img-box {
  width: 65%;
  height: 65%;
}
.header-nav-index-wrap .theme-wrap-box .theme-nav-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header-nav-index-wrap .theme-wrap-box .theme-nav-box-active {
  cursor: default;
}
.header-nav-index-wrap .theme-wrap-box .theme-nav-box-active::after {
  visibility: visible;
}
.header-nav-index-wrap .profile-box-wrap-index {
  margin-left: 3rem;
  position: relative;
}
@media only screen and (max-width: 48em) {
  .header-nav-index-wrap .profile-box-wrap-index {
    margin-left: auto;
  }
}
.header-nav-index-wrap .profile-box-wrap-index .profile-drop-box {
  position: absolute;
  top: 110%;
  right: 0%;
  width: -moz-max-content;
  width: max-content;
  border-radius: 0.8rem;
  opacity: 1 !important;
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
  padding: 1.2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.header-nav-index-wrap .profile-box-wrap-index .profile-drop-box .icon-text-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.header-nav-index-wrap .profile-box-wrap-index .profile-drop-box .icon-text-box .text {
  font-size: 1.45rem;
}
.header-nav-index-wrap .profile-box-wrap-index .profile-drop-box .icon-text-box .red {
  color: #ff0f00;
}
.header-nav-index-wrap .profile-box-wrap-index .profile-drop-box .icon-text-box .img-wrap-box .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.header-nav-index-wrap .profile-box-wrap-index .profile-drop-box .icon-text-box .img-wrap-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header-nav-index-wrap .profile-box-wrap-index .profile-box-img {
  cursor: pointer;
}
.header-nav-index-wrap .profile-box-wrap-index .profile-box-img .img-box {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.header-nav-index-wrap .profile-box-wrap-index .profile-box-img .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@media only screen and (max-width: 48em) {
  .translateX {
    transform: translateX(0%) !important;
  }
}/*# sourceMappingURL=HeaderNav.css.map */