.dashboard-overview-index-box-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .top-detail-box-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    // width: 100%;
    // background-color: red;
    grid-gap: 3rem;

    @media only screen and (max-width: 48em) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.5rem;
    }

    .total-detail-card-box {
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      padding: 1.5rem 0rem;
      gap: 1.2rem;

      @media only screen and (max-width: 48em) {
        border-radius: 0.4rem;
      }

      & > * {
        padding-left: 2rem;
      }

      .card-title {
        font-weight: 500;
        font-size: 1.5rem;
        // padding-left: 2rem;
      }

      .card-volume {
        font-size: 2.5rem;
        font-weight: 700;
        // padding-left: 2rem;
      }

      .card-amount-box {
        padding-top: 2rem;
        border-width: 0.15rem;

        .card-amount {
          font-size: 1.35rem;
        }
      }

      .card-amount-box-two {
        padding-left: unset;
        align-self: stretch;
        height: 100%;
        padding-top: unset;
        margin-top: auto;
        margin-bottom: auto;

        .process-settlement-icon-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          padding: 1.5rem 1.5rem;
          cursor: pointer;

          &:hover {
            .img-wrap {
              .img-box {
                transform: translateX(0.6rem);
              }
            }
          }

          .img-wrap {
            .img-box {
              width: 3.5rem;
              height: 3.5rem;
              display: grid;
              place-items: center;
              border-radius: 50%;
              border-width: 0.15rem;
              transition: transform 0.3s ease-in-out;

              .img {
                width: 55%;
                height: 55%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }

    .total-detail-card-box-two {
      padding-bottom: unset;
    }
  }

  .container-wrapper {
    height: calc(100vh - 40rem);

    @media only screen and (max-width: 48em) {
      height: unset;
    }

    .select-option-box {
      display: flex;
      align-items: center;
      gap: 2.5rem;
      padding-bottom: 1.5rem;
      border-width: 0.15rem;

      .selected-item {
        width: max-content;
        padding: 1rem 2rem;
        position: relative;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          border-radius: 0.8rem;
          background-color: var(--primary-base-color);
          opacity: 0.1;
          visibility: hidden;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;
          height: 0.5rem;
          width: 100%;
          background-color: var(--primary-base-color);
          //   opacity: 0.1;
          visibility: hidden;
          transform: translateY(1.5rem);
        }

        span {
          position: relative;
          z-index: 3;
          font-size: 1.3rem;
        }
      }

      .selected-item-active {
        cursor: default;
        &::after {
          visibility: visible;
        }

        &::before {
          visibility: visible;
        }

        span {
          font-weight: 600;
        }
      }
    }

    .bottom-detail-content-wrap {
      margin-top: 1.5rem;
      flex: 1;
      border-radius: 1.2rem;
      border-width: 0.15rem;
      display: flex;
      overflow-y: scroll;

      @media only screen and (max-width: 48em) {
        flex: unset;
        flex-direction: column;
        overflow-y: unset;
      }

      .left-content-wrap {
        flex: 0 0 30%;
        border-width: 0.15rem;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;

        @media only screen and (max-width: 48em) {
          flex: unset;
          width: 100%;
          overflow-y: unset;
        }

        .wrapper-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            display: none;
          }

          .multi-bar-content-box {
            width: 100%;
            margin-top: 2rem;

            .bar {
              border-radius: 0.6rem !important;
            }
          }

          .info-details-box-wrap {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            margin-top: 3.5rem;
            overflow-y: scroll;
            cursor: pointer;

            &::-webkit-scrollbar {
              display: none;
            }

            .total-detail-card-box {
              border-radius: 1.2rem;
              display: flex;
              flex-direction: column;
              padding: 2rem 0rem;
              gap: 1rem;
              position: relative;
              //   background-color: red;

              .icon-wrap {
                position: absolute;
                top: 1rem;
                right: 1.5rem;
                width: 3rem;
                height: 3rem;
                background-color: #1ace37;
                border-radius: 50%;
                display: grid;
                place-items: center;

                .img-box {
                  width: 60%;
                  height: 60%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);

                  .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
              .icon-wrap-failed {
                background-color: #ff0f00;
              }

              .icon-wrap-pending {
                background-color: #ea872d;
              }

              & > * {
                padding-left: 2rem;
              }

              .card-title {
                font-weight: 500;
                font-size: 1.2rem;
                text-transform: uppercase;
                // padding-left: 2rem;
              }

              .card-volume {
                font-size: 2rem;
                font-weight: 700;
                // padding-left: 2rem;
              }

              .card-amount-box {
                padding-top: 2rem;
                border-width: 0.15rem;

                .card-amount {
                  font-size: 1.25rem;
                }
              }
            }
          }
        }
      }

      .right-content-wrap {
        padding: 2rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @media only screen and (max-width: 48em) {
          flex: unset;
          width: 100%;
          height: 35rem;
        }

        .title-amount-filter-box {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title-amount-box {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;

            .title {
              font-weight: 500;
            }

            .amount {
              font-weight: 700;
              font-size: 3rem;
            }
          }

          .filter-box {
            height: 5rem;
          }
        }

        .chart-content-box-wrap {
          //   background-color: red;
          flex: 1;
          position: relative;

          .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
        }

        .chart-content-box-wrap-light {
          background-image: url("../../../assets/graph-line-bankbox-merchant.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
}
