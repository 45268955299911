.insights-index-wrap-box {
  flex: 1;
  // background-color: red;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .top-insight-info-box {
    // flex: 0 0 40%;
    border-width: 0.15rem;
    padding-bottom: 2.5rem;
    display: flex;
    gap: 2rem;
    align-items: stretch;
    height: max-content;
    min-height: 32rem;
    // background-color: red;

    @media only screen and (max-width: 48em) {
      flex: unset;
      height: 60rem;
      flex-direction: column;
      min-height: unset;
    }

    & > * {
      height: 100%;

      .content-insight-wrap {
        border-width: 0.15rem;
        height: 100%;
        border-radius: 1.2rem;
        // padding: 1.5rem;
      }
    }

    .left-box-insight-box {
      flex: 0 0 40%;

      //   @media only screen and (max-width: 48em) {
      //     flex: unset;
      //     height: 60rem;
      //     flex-direction: column;
      //     min-height: unset;
      //   }

      .content-insight-wrap {
        display: flex;
        flex-direction: column;
        // gap: 1.5rem;

        @media only screen and (max-width: 48em) {
          height: 26rem;
        }

        .top-content {
          flex: 0 0 50%;
          border-width: 0.15rem;
          padding-bottom: 1.5rem;
          padding: 1.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          @media only screen and (max-width: 48em) {
            flex: unset;
            gap: 1.5rem;
          }

          .title-label {
            font-size: 1.5rem;
          }

          .amount-value {
            font-size: 2.2vw;
            font-weight: 700;
            // background: linear-gradient(to bottom #ffa554, #e56f04);

            @media only screen and (max-width: 48em) {
              font-size: 2.4rem;
            }
          }
        }

        .bottom-content {
          padding: 0rem 1.5rem;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          //   gap: 1.5rem;
          //   background-color: red;

          @media only screen and (max-width: 48em) {
            padding: 2.5rem 1.5rem !important;
            flex: unset;
            gap: 1.5rem;
            // background-color: red;
          }

          .content-progress-box {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .progress-amount-box {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 1.5rem;

              .amount {
                font-size: 1.3rem;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    .right-box-insight-box {
      flex: 1;

      .content-insight-wrap {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        // @media only screen and (max-width: 48em) {
        //    padding: 1.5rem 0rem;
        //    gap: 1rem;
        //   }

        .title-box {
          .title {
            font-size: 2rem;
            font-weight: 600;
          }
        }

        .chart-content-box-wrap {
          //   background-color: red;
          flex: 1;
          position: relative;

          .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
        }

        .chart-content-box-wrap-light {
          background-image: url("../../../assets/graph-line-bankbox-merchant.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .bottom-insight-info-box {
    flex: 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    padding-top: unset;
    // overflow-y: scroll;
    gap: 1.5rem;

    .title-route-page-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 1.75rem;
        font-weight: 600;
      }

      .view-all-box-terminal {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          .icon-box {
            transform: translateX(0.2rem);
          }
        }

        .view-all {
          font-weight: 600;
        }

        .icon-box {
          transition: all 0.3s ease-in-out;
          .img-box {
            width: 3rem;
            height: 2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 48em) {
      padding: 1.5rem 0rem;
      gap: 1rem;
    }

    .all-terminals-boxes-wrap {
      flex: 1;
      //   overflow-y: scroll;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
      grid-gap: 2.5rem;
      padding-bottom: 5rem;

      @media only screen and (max-width: 48em) {
        grid-gap: 1.5rem;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .terminal-box-wrap {
        height: 15rem;
        border-radius: 1.2rem;
        border-width: 0.15rem;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        justify-content: space-between;
        cursor: pointer;

        .top-serial-box {
          display: flex;
          align-items: center;
          gap: 1rem;

          .terminal-serial-box {
            .terminal {
              font-weight: 700;
              margin-bottom: 0.4rem;
              font-size: 1.5rem;
            }
          }

          .img-terminal {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;
            display: grid;
            place-items: center;

            .img-box {
              width: 100%;
              height: 100%;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }

        .bottom-value-box {
          padding: 1.5rem;
          border-radius: 0.4rem;
          margin-top: auto;

          .amount {
            font-weight: 600;
            font-size: 1.75rem;
          }
        }
      }
    }
  }
}
