.filter-fragment-index-wrap-outer {
  position: relative;
  height: 100%;

  .filter-fragment-index-wrap {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    padding: 0.8rem 2rem;
    border-radius: 0.8rem;
    height: 100%;
    align-self: stretch;
    cursor: pointer;

    // &:hover {
    //   .img-box {
    //     transform: translateY(0.2rem);
    //   }
    // }

    .text {
      font-size: 1.35rem;
      font-weight: 500;
      text-transform: capitalize;
    }

    .img-box {
      width: 2rem;
      height: 2rem;
      transition: transform 0.3s ease-in-out;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .children-drop-box {
    // width: 35rem;
    border-radius: 1.2rem;
    height: max-content;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: absolute;
    top: 110%;
    right: 0%;
    animation: moveDownSettingDrop 0.3s ease-in-out backwards;
    // overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 48em) {
     left: 0%;
     right: unset;
    }

    // .filter-export__drop-down {
    //   &--open {
    //     transform: scale(1);
    //   }
    //   .dropdown__apply-filter-btn {
    //     z-index: 300;
    //     background-color: white;
    //   }
    // }

    // .filter-export__drop-down .drop-down__item .custom-date__form-wrap.show {
    //   display: flex;
    //   flex-direction: column;
    // }

    // .filter-export__drop-down {
    //   right: 5%;
    //   max-height: min-content;
    //   .drop-down__item {
    //     padding-bottom: 9rem;
    //   }
    // }

    // .filter-export__drop-down .drop-down__item span figure {
    //   width: 2.5rem;
    //   height: 2.5rem;
    //   overflow: hidden;
    //   border-radius: 50%;
    //   display: grid;
    //   align-items: center;
    //   justify-content: center;
    //   .basic-details-settings__app-name--input-group svg {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }

    .filter-export__drop-down {
      padding: 2rem;
      transform-origin: top right;
      transform: scale(0);
      transition: transform 0.3s ease-in-out;
      z-index: 100;

      &--open {
        transform: scale(1);
      }

      .filter-export__drop-down-wrap {
        width: max-content;
        height: max-content;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        min-width: 21rem;
        max-width: 45rem;
        padding-bottom: 2rem;
        max-height: 400px;
        &::-webkit-scrollbar {
          display: none;
        }

        @media only screen and (max-width: 48em) {
          min-width: unset;
          width: 25rem;
          max-height: unset;
          padding-bottom: unset;
          // display: none;
        }

        .dropdown__header {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          padding: 0 2rem;
          justify-content: space-between;
          padding: 1.8rem;
          border-bottom: 1px solid #eee;
  
          p {
            font-size: 1.4rem;
            color: #000;
          }
  
          p:last-child {
            color: var(--error-100, #ff0f00);
            font-weight: 500;
            cursor: pointer;
          }
        }

        .dropdown__date-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          padding: 0 2rem;
          justify-content: space-between;
          padding: 1.8rem;
          width: 100%;
          cursor: pointer;
          flex: 1;
          border-bottom: 1px solid #eee;
        }
  
        .drop-down__item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.8rem;
          align-self: stretch;
          background: var(--base-background, #f9f9f9);
          max-height: 0;
          overflow: hidden;
          transition: max-height 1s;
          position: relative;
          animation-timing-function: ease-in-out;
  
          &.active {
            max-height: 500rem;
            transition: max-height 3s;
            animation-timing-function: ease, step-start,
              cubic-bezier(0.1, 0.7, 1, 0.1);
          }
  
          .span {
            display: flex;
            padding: 1.2rem 2.4rem;
            flex-direction: row;
            width: 100%;
            align-items: center;
            border-bottom: 0.4px solid var(--ravengreyshade-50, #e5e6e6);
            gap: 1.2rem;
            cursor: pointer;
  
            p {
              color: var(--raven-black-600, #646464);
              font-size: 1.4rem;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }
          }
  
          .custom-date {
            flex-direction: column;
            width: 100%;
  
            &__select {
              display: flex;
              align-items: center;
              gap: 1.2rem;
              width: 100%;
            }
  
            &__form-wrap {
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: flex-start;
              gap: 1.6rem;
              max-height: 0;
              overflow: hidden;
              transition: max-height 1s cubic-bezier(0.445, 0.05, 0.25, 0.94);
  
              .form-group__deep-green-light .form-input {
                background: var(--raven-black-100, #eee);
                border: none;
              }
              &.show {
                max-height: 40rem;
                transition: max-height 1s cubic-bezier(0.445, 0.05, 0.24, 0.94);
              }
            }
          }
        }
  
        .drop-down__custom-date-range {
          display: flex;
          flex-direction: column;
          margin-top: 2.4rem;
  
          h5 {
            font-weight: 600;
            font-size: 1.4rem;
            color: #014345;
          }
  
          .custom-date-range__grouped-form {
            display: flex;
            align-items: center;
            gap: 2rem;
            margin-top: 1.2rem;
          }
  
          .drop-down__action-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2rem;
            width: 100%;
            margin-top: 5.2rem;
  
            padding: 2.3rem 0rem 0.3rem 0rem;
            border-top: 0.7px solid #e5e6e6;
  
            button {
              width: 50%;
  
              &:first-child {
                background: #e9f5ff;
                color: #014345;
              }
            }
          }
        }
  
        .tag-close-icon {
          transform: translateX(0);
          transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1);
          position: absolute;
          right: 2rem;
  
          &.hide {
            transform: translateX(50px);
            // display: none;
            display: flex;
            right: 0;
            transition: transform 1.5s cubic-bezier(0.47, 0, 0.745, 0.715);
          }
        }
  
        .dropdown__tags {
          display: flex;
          align-items: center;
          border-radius: 72px;
          background: var(--raven-black-100, #eee);
          box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08),
            0px 24px 48px -8px rgba(51, 51, 51, 0.04);
          padding: 0.8rem 1.2rem;
          gap: 0.8rem;
          transform: translateX(250px);
          transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  
          &.show {
            transform: translateX(0);
            transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
          }
  
          p {
            color: #000;
            text-align: center;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
          }
  
          figure {
            width: 1.5rem;
            height: 1.5rem;
            display: grid;
            place-items: center;
  
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
  
        .dropdown__apply-filter-btn {
          display: flex;
          width: 100%;
          padding: 2rem;
          position: absolute;
          bottom: 0;
  
          button {
            width: 100%;
          }
        }
      }

      .drop-down__switch {
        display: flex;
        width: 100%;
        cursor: pointer;
        justify-content: flex-start;
        gap: 1.2rem;
        border-bottom: 0.8px solid #e5e6e6;
        span {
          gap: 0.4rem;
          padding-bottom: 1.6rem;

          p {
            font-weight: 400;
            font-size: 1.4rem;
            display: grid;
            place-items: center;
            padding: 1.2rem 1.6rem;
            border-radius: 8px;
            color: #1b1b1b;
          }

          &.active {
            border-bottom: 4px solid #014345;
            p {
              color: #014345;
              font-weight: 600;
              background: #e9f5ff;
            }
          }
        }
      }

      .drop-down__custom-date-range {
        display: flex;
        flex-direction: column;
        margin-top: 2.4rem;

        h5 {
          font-weight: 600;
          font-size: 1.4rem;
          color: #014345;
        }

        .custom-date-range__grouped-form {
          display: flex;
          align-items: center;
          gap: 2rem;
          margin-top: 1.2rem;
        }

        .drop-down__action-btns {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2rem;
          width: 100%;
          margin-top: 5.2rem;

          padding: 2.3rem 0rem 0.3rem 0rem;
          border-top: 0.7px solid #e5e6e6;

          button {
            width: 50%;

            &:first-child {
              background: #e9f5ff;
              color: #014345;
            }
          }
        }
      }
    }

    .filter-export__drop-down-closer {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      right: 0;

      display: none;
      visibility: none;

      &--opened {
        display: unset;
        visibility: unset;
      }
    }
  }
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    //   opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}
