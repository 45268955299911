.transaction-dashboard-index-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
}
.transaction-dashboard-index-wrap::-webkit-scrollbar {
  display: none;
}
.transaction-dashboard-index-wrap .search-export-filter-box {
  display: flex;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 10;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap .search-export-filter-box {
    flex-wrap: wrap;
    margin-bottom: 2.5rem;
  }
}
.transaction-dashboard-index-wrap .search-export-filter-box .search-box {
  width: 30rem;
  margin-right: auto;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap .search-export-filter-box .search-box {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.transaction-dashboard-index-wrap .search-export-filter-box .filter-export-pagination-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap .search-export-filter-box .filter-export-pagination-box {
    width: 100%;
    align-items: stretch;
    min-height: 4.5rem;
  }
  .transaction-dashboard-index-wrap .search-export-filter-box .filter-export-pagination-box .pagination-fragment-index-wrap {
    margin-left: auto;
    width: -moz-max-content;
    width: max-content;
  }
}
.transaction-dashboard-index-wrap .table-content-box {
  flex: 1;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
}
.transaction-dashboard-index-wrap .table-content-box::-webkit-scrollbar {
  display: none;
}
.transaction-dashboard-index-wrap .table-content-box .big-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.transaction-dashboard-index-wrap .table-content-box .big-table::-webkit-scrollbar {
  display: none;
}
.transaction-dashboard-index-wrap .table-content-box .big-table .table thead {
  position: sticky;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap .table-content-box .big-table {
    display: none;
  }
}/*# sourceMappingURL=TransactionIndex.css.map */