.auth-layout-index-wrap {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 58em) {
  .auth-layout-index-wrap {
    width: 100%;
    height: 100%;
  }
}
.auth-layout-index-wrap .header-wrap-index-box {
  min-height: 6.5rem;
  width: -moz-max-content;
  width: max-content;
  align-self: center;
  margin: 3rem 0rem;
}
.auth-layout-index-wrap .header-wrap-index-box .logo-no-page-box {
  align-self: center;
}
.auth-layout-index-wrap .header-wrap-index-box .logo-no-page-box .img-box {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.auth-layout-index-wrap .header-wrap-index-box .logo-no-page-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50%;
}
.auth-layout-index-wrap .main-wrap-index-box {
  flex: 1;
  overflow-y: scroll;
  padding: 2rem;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: grid;
  place-items: center;
  align-items: flex-start;
}
.auth-layout-index-wrap .main-wrap-index-box::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 58em) {
  .auth-layout-index-wrap .main-wrap-index-box .content-wrap {
    width: 100%;
  }
}
.auth-layout-index-wrap .footer-wrap-index-box {
  min-height: 5rem;
  display: flex;
  justify-content: center;
  padding: 0rem 0rem 1.2rem 0rem;
}
.auth-layout-index-wrap .footer-wrap-index-box .size-wrap-global {
  display: flex;
  justify-content: center;
}
.auth-layout-index-wrap .footer-wrap-index-box .size-wrap-global .power-by-box {
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  padding: 1rem 2rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 0.15rem solid rgba(208, 213, 221, 0.5);
}
.auth-layout-index-wrap .footer-wrap-index-box .size-wrap-global .power-by-box .text {
  font-size: 1.15rem;
}
.auth-layout-index-wrap .footer-wrap-index-box .size-wrap-global .power-by-box .img-wrap-box .img-box {
  width: 8rem;
  height: 1.2rem;
}
.auth-layout-index-wrap .footer-wrap-index-box .size-wrap-global .power-by-box .img-wrap-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=AuthLayout.css.map */