.no-content-page-index {
  height: calc(100vh - 22rem);
  display: grid;
  place-items: center;
  width: 100%;
  border-radius: 1.6rem;
  // background-color: red;
  padding: 1.2rem;

  .loading-wrap {
    width: 15rem;
    height: 15rem;

    .img-box {
      width: 100%;
      height: 100%;

      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .wrap-center-outer-layer {
    width: 100%;
    height: 100%;
    border-radius: 1.6rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .top-box-wrap {
      flex: 0 0 30%;
      background-image: url("../../assets/empty-bg-lines.png");
      background-position: center;
      background-size: 110%;
      background-repeat: no-repeat;
    }

    .bottom-box-wrap {
      flex: 1;
      border-top-right-radius: 3rem;
      border-top-left-radius: 3rem;
      position: relative;
    }

    .wrap-center {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;

      .round-img-box {
        width: 19rem;
        height: 19rem;
        border-radius: 50%;
        padding: 1.4rem;
        // background-color: red;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        .round-grey-img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: grid;
          place-items: center;

          .img-box {
            width: 55%;
            height: 55%;

            .img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .title-text-box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        .title {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 0.7rem;
          text-align: center;
        }

        .text {
          font-size: 1.5rem;
          text-align: center;
        }
      }

      .img-center-box {
        width: 20rem;
        height: 20rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .img-box {
          width: 75%;
          height: 75%;

          .img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
