.dashboard-overview-index-box-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  grid-gap: 3rem;
}
@media only screen and (max-width: 48em) {
  .dashboard-overview-index-box-wrap .top-detail-box-wrap {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box {
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0rem;
  gap: 1.2rem;
}
@media only screen and (max-width: 48em) {
  .dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box {
    border-radius: 0.4rem;
  }
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box > * {
  padding-left: 2rem;
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box .card-title {
  font-weight: 500;
  font-size: 1.5rem;
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box .card-volume {
  font-size: 2.5rem;
  font-weight: 700;
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box .card-amount-box {
  padding-top: 2rem;
  border-width: 0.15rem;
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box .card-amount-box .card-amount {
  font-size: 1.35rem;
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box .card-amount-box-two {
  padding-left: unset;
  align-self: stretch;
  height: 100%;
  padding-top: unset;
  margin-top: auto;
  margin-bottom: auto;
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box .card-amount-box-two .process-settlement-icon-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 1.5rem 1.5rem;
  cursor: pointer;
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box .card-amount-box-two .process-settlement-icon-box:hover .img-wrap .img-box {
  transform: translateX(0.6rem);
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box .card-amount-box-two .process-settlement-icon-box .img-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  border-width: 0.15rem;
  transition: transform 0.3s ease-in-out;
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box .card-amount-box-two .process-settlement-icon-box .img-wrap .img-box .img {
  width: 55%;
  height: 55%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-overview-index-box-wrap .top-detail-box-wrap .total-detail-card-box-two {
  padding-bottom: unset;
}
.dashboard-overview-index-box-wrap .container-wrapper {
  height: calc(100vh - 40rem);
}
@media only screen and (max-width: 48em) {
  .dashboard-overview-index-box-wrap .container-wrapper {
    height: unset;
  }
}
.dashboard-overview-index-box-wrap .container-wrapper .select-option-box {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  padding-bottom: 1.5rem;
  border-width: 0.15rem;
}
.dashboard-overview-index-box-wrap .container-wrapper .select-option-box .selected-item {
  width: -moz-max-content;
  width: max-content;
  padding: 1rem 2rem;
  position: relative;
  cursor: pointer;
}
.dashboard-overview-index-box-wrap .container-wrapper .select-option-box .selected-item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 0.8rem;
  background-color: var(--primary-base-color);
  opacity: 0.1;
  visibility: hidden;
}
.dashboard-overview-index-box-wrap .container-wrapper .select-option-box .selected-item::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  height: 0.5rem;
  width: 100%;
  background-color: var(--primary-base-color);
  visibility: hidden;
  transform: translateY(1.5rem);
}
.dashboard-overview-index-box-wrap .container-wrapper .select-option-box .selected-item span {
  position: relative;
  z-index: 3;
  font-size: 1.3rem;
}
.dashboard-overview-index-box-wrap .container-wrapper .select-option-box .selected-item-active {
  cursor: default;
}
.dashboard-overview-index-box-wrap .container-wrapper .select-option-box .selected-item-active::after {
  visibility: visible;
}
.dashboard-overview-index-box-wrap .container-wrapper .select-option-box .selected-item-active::before {
  visibility: visible;
}
.dashboard-overview-index-box-wrap .container-wrapper .select-option-box .selected-item-active span {
  font-weight: 600;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap {
  margin-top: 1.5rem;
  flex: 1;
  border-radius: 1.2rem;
  border-width: 0.15rem;
  display: flex;
  overflow-y: scroll;
}
@media only screen and (max-width: 48em) {
  .dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap {
    flex: unset;
    flex-direction: column;
    overflow-y: unset;
  }
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap {
  flex: 0 0 30%;
  border-width: 0.15rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
@media only screen and (max-width: 48em) {
  .dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap {
    flex: unset;
    width: 100%;
    overflow-y: unset;
  }
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content::-webkit-scrollbar {
  display: none;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .multi-bar-content-box {
  width: 100%;
  margin-top: 2rem;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .multi-bar-content-box .bar {
  border-radius: 0.6rem !important;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3.5rem;
  overflow-y: scroll;
  cursor: pointer;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap::-webkit-scrollbar {
  display: none;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box {
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  gap: 1rem;
  position: relative;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box .icon-wrap {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
  background-color: #1ace37;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box .icon-wrap .img-box {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box .icon-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box .icon-wrap-failed {
  background-color: #ff0f00;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box .icon-wrap-pending {
  background-color: #ea872d;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box > * {
  padding-left: 2rem;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box .card-title {
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box .card-volume {
  font-size: 2rem;
  font-weight: 700;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box .card-amount-box {
  padding-top: 2rem;
  border-width: 0.15rem;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .left-content-wrap .wrapper-content .info-details-box-wrap .total-detail-card-box .card-amount-box .card-amount {
  font-size: 1.25rem;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .right-content-wrap {
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media only screen and (max-width: 48em) {
  .dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .right-content-wrap {
    flex: unset;
    width: 100%;
    height: 35rem;
  }
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .right-content-wrap .title-amount-filter-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .right-content-wrap .title-amount-filter-box .title-amount-box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .right-content-wrap .title-amount-filter-box .title-amount-box .title {
  font-weight: 500;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .right-content-wrap .title-amount-filter-box .title-amount-box .amount {
  font-weight: 700;
  font-size: 3rem;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .right-content-wrap .title-amount-filter-box .filter-box {
  height: 5rem;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .right-content-wrap .chart-content-box-wrap {
  flex: 1;
  position: relative;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .right-content-wrap .chart-content-box-wrap .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.dashboard-overview-index-box-wrap .container-wrapper .bottom-detail-content-wrap .right-content-wrap .chart-content-box-wrap-light {
  background-image: url("../../../assets/graph-line-bankbox-merchant.png");
  background-repeat: no-repeat;
  background-size: cover;
}/*# sourceMappingURL=OverviewIndex.css.map */