.date-time-fragment-box-wrap {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.date-time-fragment-box-wrap .date {
  font-weight: 600;
  font-size: 1.55rem;
}
.date-time-fragment-box-wrap .date-two {
  font-size: 1.15rem;
  color: #676767;
}/*# sourceMappingURL=DateFragmentBox.css.map */