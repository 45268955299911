.export-fragment-index-wrap {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 0.8rem 2rem;
  border-radius: 0.8rem;
  height: 100%;
  align-self: stretch;
  cursor: pointer;
}
.export-fragment-index-wrap:hover .img-box {
  transform: translateY(0.2rem);
}
.export-fragment-index-wrap .text {
  font-size: 1.35rem;
  font-weight: 500;
  text-transform: capitalize;
}
.export-fragment-index-wrap .img-box {
  width: 2rem;
  height: 2rem;
  transition: transform 0.3s ease-in-out;
}
.export-fragment-index-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=ExportFragment.css.map */