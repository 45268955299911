.insights-index-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.insights-index-wrap-box .top-insight-info-box {
  border-width: 0.15rem;
  padding-bottom: 2.5rem;
  display: flex;
  gap: 2rem;
  align-items: stretch;
  height: -moz-max-content;
  height: max-content;
  min-height: 32rem;
}
@media only screen and (max-width: 48em) {
  .insights-index-wrap-box .top-insight-info-box {
    flex: unset;
    height: 60rem;
    flex-direction: column;
    min-height: unset;
  }
}
.insights-index-wrap-box .top-insight-info-box > * {
  height: 100%;
}
.insights-index-wrap-box .top-insight-info-box > * .content-insight-wrap {
  border-width: 0.15rem;
  height: 100%;
  border-radius: 1.2rem;
}
.insights-index-wrap-box .top-insight-info-box .left-box-insight-box {
  flex: 0 0 40%;
}
.insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 48em) {
  .insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap {
    height: 26rem;
  }
}
.insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap .top-content {
  flex: 0 0 50%;
  border-width: 0.15rem;
  padding-bottom: 1.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
@media only screen and (max-width: 48em) {
  .insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap .top-content {
    flex: unset;
    gap: 1.5rem;
  }
}
.insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap .top-content .title-label {
  font-size: 1.5rem;
}
.insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap .top-content .amount-value {
  font-size: 2.2vw;
  font-weight: 700;
}
@media only screen and (max-width: 48em) {
  .insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap .top-content .amount-value {
    font-size: 2.4rem;
  }
}
.insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap .bottom-content {
  padding: 0rem 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
@media only screen and (max-width: 48em) {
  .insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap .bottom-content {
    padding: 2.5rem 1.5rem !important;
    flex: unset;
    gap: 1.5rem;
  }
}
.insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap .bottom-content .content-progress-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap .bottom-content .content-progress-box .progress-amount-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}
.insights-index-wrap-box .top-insight-info-box .left-box-insight-box .content-insight-wrap .bottom-content .content-progress-box .progress-amount-box .amount {
  font-size: 1.3rem;
  font-weight: 500;
}
.insights-index-wrap-box .top-insight-info-box .right-box-insight-box {
  flex: 1;
}
.insights-index-wrap-box .top-insight-info-box .right-box-insight-box .content-insight-wrap {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.insights-index-wrap-box .top-insight-info-box .right-box-insight-box .content-insight-wrap .title-box .title {
  font-size: 2rem;
  font-weight: 600;
}
.insights-index-wrap-box .top-insight-info-box .right-box-insight-box .content-insight-wrap .chart-content-box-wrap {
  flex: 1;
  position: relative;
}
.insights-index-wrap-box .top-insight-info-box .right-box-insight-box .content-insight-wrap .chart-content-box-wrap .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.insights-index-wrap-box .top-insight-info-box .right-box-insight-box .content-insight-wrap .chart-content-box-wrap-light {
  background-image: url("../../../assets/graph-line-bankbox-merchant.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.insights-index-wrap-box .bottom-insight-info-box {
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  padding-top: unset;
  gap: 1.5rem;
}
.insights-index-wrap-box .bottom-insight-info-box .title-route-page-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.insights-index-wrap-box .bottom-insight-info-box .title-route-page-box .title {
  font-size: 1.75rem;
  font-weight: 600;
}
.insights-index-wrap-box .bottom-insight-info-box .title-route-page-box .view-all-box-terminal {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.insights-index-wrap-box .bottom-insight-info-box .title-route-page-box .view-all-box-terminal:hover .icon-box {
  transform: translateX(0.2rem);
}
.insights-index-wrap-box .bottom-insight-info-box .title-route-page-box .view-all-box-terminal .view-all {
  font-weight: 600;
}
.insights-index-wrap-box .bottom-insight-info-box .title-route-page-box .view-all-box-terminal .icon-box {
  transition: all 0.3s ease-in-out;
}
.insights-index-wrap-box .bottom-insight-info-box .title-route-page-box .view-all-box-terminal .icon-box .img-box {
  width: 3rem;
  height: 2rem;
}
.insights-index-wrap-box .bottom-insight-info-box .title-route-page-box .view-all-box-terminal .icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media only screen and (max-width: 48em) {
  .insights-index-wrap-box .bottom-insight-info-box {
    padding: 1.5rem 0rem;
    gap: 1rem;
  }
}
.insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  grid-gap: 2.5rem;
  padding-bottom: 5rem;
}
@media only screen and (max-width: 48em) {
  .insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap {
    grid-gap: 1.5rem;
  }
}
.insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap::-webkit-scrollbar {
  display: none;
}
.insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap .terminal-box-wrap {
  height: 15rem;
  border-radius: 1.2rem;
  border-width: 0.15rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: space-between;
  cursor: pointer;
}
.insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap .terminal-box-wrap .top-serial-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap .terminal-box-wrap .top-serial-box .terminal-serial-box .terminal {
  font-weight: 700;
  margin-bottom: 0.4rem;
  font-size: 1.5rem;
}
.insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap .terminal-box-wrap .top-serial-box .img-terminal {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap .terminal-box-wrap .top-serial-box .img-terminal .img-box {
  width: 100%;
  height: 100%;
}
.insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap .terminal-box-wrap .top-serial-box .img-terminal .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap .terminal-box-wrap .bottom-value-box {
  padding: 1.5rem;
  border-radius: 0.4rem;
  margin-top: auto;
}
.insights-index-wrap-box .bottom-insight-info-box .all-terminals-boxes-wrap .terminal-box-wrap .bottom-value-box .amount {
  font-weight: 600;
  font-size: 1.75rem;
}/*# sourceMappingURL=InsightIndex.css.map */