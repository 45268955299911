// @import "../breakpoints";

.container-wraaper-box {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1.6rem;
  height: calc(100vh - 22rem);
  // flex: 1;
  // overflow: hidden;
  // background-color: red;
  overflow-y: scroll;
  margin-top: 1rem;
  // padding-bottom: 10rem;

  @media only screen and (max-width: 58em) {
    flex: unset;
    height: 100%;
    min-height: unset;
    // overflow-y: scroll;
  }

  @media only screen and (max-width: 48em) {
    min-height: calc(100vh - 24rem);
    // flex: 1;
  }

  .header-box {
    position: relative;
    z-index: 5;
    min-height: 6rem;
    display: flex;
    align-items: center;
    padding: 0rem 3rem;

    .bulk-select {
      width: max-content;
    }

    .clear-bulk-list {
      color: var(--primary-purple);
      font-size: 1.45rem;
      font-weight: 600;
      text-transform: capitalize;
      cursor: pointer;
    }

    @media only screen and (max-width: 42em) {
      padding: 0rem 1.5rem;
    }

    .back-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover .icon {
        animation: moveBackFort 0.3s ease-in-out;
      }

      .icon {
        width: 1.45rem;
        height: 1.45rem;
        margin-right: 0.1rem;
        fill: rgba(80, 95, 109, 0.32);
        transform: scale(1.1);
      }

      .text {
        font-size: 1.6rem;
        text-transform: capitalize;
        font-weight: 600;
      }
    }

    .step-box {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .box {
        display: flex;
        align-items: center;
        gap: 0.4rem;

        .num {
          width: 3.2rem;
          height: 3.2rem;
          border-radius: 50%;
          background-color: rgba(189, 189, 189, 0.2);
          display: grid;
          place-items: center;
          // cursor: pointer;

          span {
            color: rgba(22, 45, 76, 0.4);
            font-size: 1.2rem;
          }
        }

        .text {
          margin-top: 0.1rem;
          color: #bdbdbd;
          font-size: 1.2rem;

          @media only screen and (max-width: 42em) {
            display: none;
          }
        }
      }

      .box-active {
        .num {
          background-color: var(--primary-purple);

          span {
            color: #ffffff;
            font-weight: 600;
          }
        }

        .text {
          color: #020202;

          @media only screen and (max-width: 42em) {
            display: none;
          }
        }
      }
    }
  }

  .content-box {
    position: relative;
    z-index: 2;
    // background-color: blue;
    flex: 1;
    display: flex;
    // justify-content: center;
    overflow-y: scroll;
    position: relative;
    //   z-index: 2;
    width: 100%;
    //  margin-bottom: 10rem;

    // margin-bottom: 10rem;

    @media only screen and (max-width: 58em) {
      // flex: unset;
      // background-color: red;
      // width: 90vw;
      // min-height: 70rem ;
      overflow-x: hidden;
    }

    & > * {
      margin: 0rem auto;
    }

    .wrap-container {
      // flex: 1;
      // overflow-y: scroll;
      display: flex;
      flex-direction: column;
      //   padding: 0rem .15rem;
      // justify-content: center;
      // padding-bottom: 80rem;
      // background-color: red;
      // min-height: 50rem;
      padding: 1.5rem;
    }

    .wrap-container-two {
      flex: 1;
    }

    @media only screen and (max-width: 42em) {
      &::-webkit-scrollbar {
        display: none;
      }
    }

    // &::-webkit-scrollbar:horizontal {
    //   display: none;
    // }

    &::-webkit-scrollbar {
      display: none;
    }

    // &:hover::-webkit-scrollbar {
    //   display: block;
    // }

    /* width */
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none !important;
      background-color: transparent !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #020202;
      border-radius: 30rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #020202;
    }
    // .wrap{
    //     width: 45rem;
    // }
  }

  .footer-box {
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 100%;
    padding: 1rem 0rem;
    display: grid;
    place-items: center;
    z-index: 10;

    .table-pagination-box {
      display: flex;
      justify-content: center;
      padding: 0rem 3rem;
      // margin-bottom: 5rem;

      @media only screen and (max-width: 42em) {
        padding: 0rem 1.5rem;
      }
    }

    .wrap {
      width: 40rem;

      @media only screen and (max-width: 42em) {
        width: 90%;
      }
      form {
        .form-group {
          input {
            font-family: "br_firma", sans-serif;
          }
        }
      }
    }
  }
}

@keyframes moveBackFort {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.2rem);
  }
  100% {
    transform: translateX(0rem);
  }
}

// @include xs {
//   .container-wraaper-box {
//     padding: 0 2rem;

//     .content-box {
//       flex: unset !important;
//     }

//       .dashboard-settings-wrap-box {
//         display: flex;
//         flex-wrap: wrap;

//         .left-box {
//           flex: unset !important;
//           width: 100%;
//           padding: 3rem 0 ;
//         }
//       }

//   }

//   .payroll-manual-wrap-box {
//     width: 100% !important;
//   }

//   .header-box {
//     padding: 0 !important;
//     width: 100%;
//     gap: 2rem;

//   }

//   .step-box {
//     overflow: auto;
//     width: 100%;
//   }

// }
