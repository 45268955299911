.Flier-two-terminal-index-wrap {
  // flex: 1;
  margin-top: -1rem;
  min-height: 65rem;
  background-color: #014345;
  margin-bottom: 2.5rem;
  position: relative;
  padding: 2rem;
  display: flex;
  flex-direction: column;
//   background-image: url("../../../../assets/flier-bg-wave-terminal.png");
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   background: linear-gradient(to right, #014345 50%, #fff 50%) !important;
// //   background-size: 200% 100%;
//   animation: wave 3s linear infinite;

  > * {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0;
    background-image: url("../../../../assets/lier-bg-terminal.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
	// mix-blend-mode: color-burn;
	opacity: .03;
    // display: none;
  }

  .business-logo-box {
    .img-box {
      width: 5rem;
      height: 5rem;
      // background-color: red;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
      }
    }

    .img-box-two {
      width: 12rem;
      height: 4rem;
      // background-color: red;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .account-detail-box-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    // gap: 3rem;

    .bank-name {
      display: flex;
      flex-direction: column;

      .bank {
        font-size: 1.25rem;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 450;
        margin-bottom: 0.3rem;
      }

      .value {
        font-size: 1.75rem;
        font-weight: 700;
        color: #ffffff;
      }
    }

    .mascot-account-number-box {
      width: 100%;
      // height: 15rem;
      position: relative;
      // margb

      & > * {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 15rem;
        height: 15rem;
        // background-color: red;
        z-index: 1;
        transform: translateY(-65%);
        background-image: url("../../../../assets/Easy_To_use_Merged 1flier-mascot-img (1).png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .account-detail {
        padding: 2rem;
        margin-top: 3rem;
        // margin-bottom: 3rem;
        border-radius: 1.2rem;
        background-color: rgba(26, 83, 85, 1);
        // box-shadow: 0rem 0.4rem 8rem 0rem rgba(0, 0, 0, 0.4);
        //  filter: drop-shadow(0rem 0.4rem 8rem rgba(0, 0, 0, 0.4));
        filter: drop-shadow(0rem.4rem 6rem rgba(0, 0, 0, 0.3));
        display: flex;
        flex-direction: column;
        width: 100%;

        .account {
          font-size: 1.6rem;
          color: rgba(255, 255, 255, 0.6);
          font-weight: 450;
          margin-bottom: 0.3rem;
        }

        .value {
          font-size: 4.5rem;
          font-weight: 700;
          line-height: 5rem;
          color: #ffffff;
        }
      }
    }

    .account-name-box {
      margin-top: 2rem;
      .value {
        // color: rgba(155, 229, 201, 1);
		color: #ffffff;
      }
    }
  }

  .qr-code-text-box-wrap {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    gap: 2rem;

    .bank-name {
      display: flex;
      flex-direction: column;

      .bank {
        font-size: 1.25rem;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 450;
        margin-bottom: 0.3rem;
      }

      .value {
        font-size: 1.45rem;
        font-weight: 700;
        color: #ffffff;
      }
    }

    .qr-code-box {
      width: 10rem;
      height: 10rem;
      display: grid;
      place-items: center;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 0.8rem;

      .qr {
        width: 80%;
        height: 80%;
        // background-color: red;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .underline {
    width: 100%;
    height: 0.05rem;
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 2rem;
  }

  .we-support-box {
    width: max-content;
    align-self: center;
    margin-top: auto;
    display: flex;
    flex-direction: column;

    .text {
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.8rem;
    }

    .support-img-box {
      margin-top: 1rem;
      width: 22rem;
      height: 3rem;
      // background-color: red;
      // background-size: contain;
      // background-repeat: no-repeat;
      // background-position: center;
      // background-image: url('../../../../../../assets/support-icon-images.png');

      .img-box {
        width: 100%;
        height: 100%;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}


@keyframes wave {
	0% {
	  background-position: 0 0;
	}
	100% {
	  background-position: -200% 0;
	}
  }