

.date-time-fragment-box-wrap{
    display: flex;
    flex-direction: column;
    gap: .3rem;

    .date{
        font-weight: 600;
        font-size: 1.55rem;
        // text-transform: capitalize;
    } 

    .date-two{
        font-size: 1.15rem;
        color: #676767;
    }
}