.container-wrapper-insight {
  height: calc(100vh - 46.5rem);
}

.transaction-dashboard-index-wrap-insight-details-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
}
.transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box {
  height: 23rem;
  border-radius: 1.6rem;
  border-width: 0.15rem;
  margin-bottom: 1rem;
  display: flex;
  padding: 1rem 1.5rem;
  gap: 3rem;
  overflow-y: scroll;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box {
    height: unset;
    flex-direction: column;
  }
}
.transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box::-webkit-scrollbar {
  display: none;
}
.transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box .left-box-wrap {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box .left-box-wrap {
    flex: unset;
    height: 17rem;
    width: 100%;
  }
}
.transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box .left-box-wrap .wrap-content {
  border-radius: 1.2rem;
  flex: 1;
  background-image: url("../../../assets/Group 238544terminal-img-device.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box .right-box-wrap {
  flex: 1;
  padding: 2rem;
}
.transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box .right-box-wrap .wrap-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-gap: 2.5rem;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box .right-box-wrap .wrap-content {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }
}
.transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box .right-box-wrap .wrap-content .label {
  font-size: 1.25rem;
  margin-bottom: 0.35rem;
}
.transaction-dashboard-index-wrap-insight-details-main .top-detail-wrap-insight-box .right-box-wrap .wrap-content .value {
  font-weight: 600;
  font-size: 1.5rem;
  color: #1c2127;
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details::-webkit-scrollbar {
  display: none;
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .search-export-filter-box {
  display: flex;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 10;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .search-export-filter-box {
    flex-wrap: wrap;
    margin-bottom: 2.5rem;
  }
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .search-export-filter-box .search-box {
  width: 30rem;
  margin-right: auto;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .search-export-filter-box .search-box {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .search-export-filter-box .filter-export-pagination-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .search-export-filter-box .filter-export-pagination-box {
    width: 100%;
    align-items: stretch;
    min-height: 4.5rem;
  }
  .transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .search-export-filter-box .filter-export-pagination-box .pagination-fragment-index-wrap {
    margin-left: auto;
    width: -moz-max-content;
    width: max-content;
  }
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .table-content-box {
  flex: 1;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .table-content-box::-webkit-scrollbar {
  display: none;
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .table-content-box .big-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .table-content-box .big-table .no-search-empty-box {
  min-height: 25rem;
  width: 100%;
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .table-content-box .big-table::-webkit-scrollbar {
  display: none;
}
.transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .table-content-box .big-table .table thead {
  position: sticky;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 48em) {
  .transaction-dashboard-index-wrap-insight-details-main .transaction-dashboard-index-wrap-insight-details .table-content-box .big-table {
    display: none;
  }
}/*# sourceMappingURL=TerminalDetailsInsight.css.map */