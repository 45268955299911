.transaction-dashboard-index-wrap {
  // background-color: red;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .search-export-filter-box {
    display: flex;
    // align-items: center;
    margin-bottom: 1.5rem;
    // background-color: red;
    position: relative;
    z-index: 10;

    @media only screen and (max-width: 48em) {
      flex-wrap: wrap;
      margin-bottom: 2.5rem;
    }

    .search-box {
      width: 30rem;
      margin-right: auto;

      @media only screen and (max-width: 48em) {
        width: 100%;
        margin-bottom: 1.5rem;
      }
    }

    .filter-export-pagination-box {
      display: flex;
      align-items: center;
      gap: 2rem;

      @media only screen and (max-width: 48em) {
        // margin-left: auto;
        width: 100%;
        align-items: stretch;
        min-height: 4.5rem;

        .pagination-fragment-index-wrap{
            margin-left: auto;
            width: max-content;
        }
      }
    }
  }

  .table-content-box {
    flex: 1;
    // background-color: blue;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;

    &::-webkit-scrollbar {
      display: none;
    }

    .big-table {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
      //   .table thead tr {
      //     & > *:nth-last-child(2) {
      //       //   text-align: center;

      //     }
      //   }

      .table thead {
        // background-color: red !important;
        position: sticky;
        top: 0;
        left: 0;
      }

      @media only screen and (max-width: 48em) {
        display: none;
      }
    }
  }
}
