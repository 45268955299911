.alert-receiver-modal-wrap-index {
  display: flex;
  flex-direction: column;
  min-height: 35rem;
  max-height: 60rem;
}
.alert-receiver-modal-wrap-index .loading-wrap {
  width: 100%;
  height: 35rem;
  display: grid;
  place-items: center;
}
.alert-receiver-modal-wrap-index .loading-wrap .img-box {
  width: 50%;
  height: 50%;
}
.alert-receiver-modal-wrap-index .loading-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.alert-receiver-modal-wrap-index .title-text-box {
  margin-top: -1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 1rem;
}
.alert-receiver-modal-wrap-index .title-text-box .title {
  font-weight: 600;
  font-size: 2.3rem;
}
.alert-receiver-modal-wrap-index .title-text-box .text {
  color: rgb(103, 103, 103);
}
.alert-receiver-modal-wrap-index .search-box-wrap {
  margin-top: 2rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box {
  margin-top: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 3rem;
  gap: 2.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .amount-show-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .amount-show-box .text-amount- {
  font-size: 1.25rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .amount-show-box .text-amount- span {
  color: unset !important;
}
.alert-receiver-modal-wrap-index .receiver-list-box .amount-show-box .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .amount-show-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap {
  display: flex;
  flex-direction: column;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .label {
  margin-bottom: 0.4rem;
  font-size: 1.3rem;
  color: #020202;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box {
  padding: 1.5rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .name-account-num-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .name-account-num-box .name {
  font-size: 1.35rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .name-account-num-box .acc-num {
  font-size: 2rem;
  font-weight: 700;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .icon-wrap-box .icon-wrap {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border-width: 0.15rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .icon-wrap-box .icon-wrap .img-box {
  width: 100%;
  height: 100%;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .icon-wrap-box .icon-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.alert-receiver-modal-wrap-index .receiver-list-box .no-alert-to-view-box {
  display: grid;
  place-items: center;
  flex: 1;
}
.alert-receiver-modal-wrap-index .receiver-list-box .no-alert-to-view-box p {
  text-align: center;
  font-size: 1.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box::-webkit-scrollbar {
  display: none;
}
.alert-receiver-modal-wrap-index .receiver-list-box .form-group .form-label {
  margin-bottom: 0.6rem;
  font-size: 1.3rem;
  color: #020202;
  text-transform: capitalize;
}
.alert-receiver-modal-wrap-index .receiver-list-box .form-group .select-option-list-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .form-group .select-option-list-box .item-select {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  cursor: pointer !important;
}
.alert-receiver-modal-wrap-index .receiver-list-box .form-group .select-option-list-box .item-select .name {
  font-size: 1.25rem;
  cursor: pointer;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.6rem;
  border-width: 0.15rem;
  padding: 2rem 1.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .delete-box {
  background-color: #fff5f5;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .delete-box .img-box {
  width: 55%;
  height: 55%;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .delete-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .name-num-box {
  display: flex;
  flex-direction: column;
  grid-gap: 0.4rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .name-num-box .name {
  font-weight: 600;
  font-size: 1.6rem;
  text-transform: capitalize;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .name-num-box .number {
  font-size: 1.25rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .note-box-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem 1.5rem;
  background-color: rgb(255, 246, 237);
  border-radius: 0.8rem;
  margin-bottom: 1rem;
  border-color: rgb(234, 135, 45);
}
.alert-receiver-modal-wrap-index .receiver-list-box .note-box-wrap .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .note-box-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.alert-receiver-modal-wrap-index .receiver-list-box .note-box-wrap .text {
  font-size: 1.4rem;
}/*# sourceMappingURL=AlertReceivers.css.map */