.loading-congig-wrap-inderx-page{
    position: fixed;
    background-color: white;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;

    .loading-wrap {
        width: 20rem;
        height: 20rem;
    
        .img-box {
          width: 100%;
          height: 100%;
    
          .img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }
}