.container-wrapper-insight {
  height: calc(100vh - 46.5rem);
}
.transaction-dashboard-index-wrap-insight-details-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;

  .top-detail-wrap-insight-box {
    height: 23rem;
    border-radius: 1.6rem;
    border-width: 0.15rem;
    margin-bottom: 1rem;
    display: flex;
    padding: 1rem 1.5rem;
    gap: 3rem;
    overflow-y: scroll;

    @media only screen and (max-width: 48em) {
      height: unset;
      flex-direction: column;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .left-box-wrap {
      flex: 0 0 20%;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 48em) {
        flex: unset;
        height: 17rem;
        width: 100%;
      }

      .wrap-content {
        border-radius: 1.2rem;
        flex: 1;
        background-image: url("../../../assets/Group 238544terminal-img-device.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center bottom;
      }
    }

    .right-box-wrap {
      flex: 1;
      //   background-color: red;
      padding: 2rem;

      .wrap-content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
        grid-gap: 2.5rem;

        @media only screen and (max-width: 48em) {
          grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
        }

        .label {
          font-size: 1.25rem;
          margin-bottom: 0.35rem;
        }

        .value {
          font-weight: 600;
          font-size: 1.5rem;
          color: #1c2127;
        }
      }
    }
  }

  .transaction-dashboard-index-wrap-insight-details {
    // background-color: red;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    .search-export-filter-box {
      display: flex;
      // align-items: center;
      margin-bottom: 1.5rem;
      // background-color: red;
      position: relative;
      z-index: 10;

      @media only screen and (max-width: 48em) {
        flex-wrap: wrap;
        margin-bottom: 2.5rem;
      }

      .search-box {
        width: 30rem;
        margin-right: auto;

        @media only screen and (max-width: 48em) {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }

      .filter-export-pagination-box {
        display: flex;
        align-items: center;
        gap: 2rem;

        @media only screen and (max-width: 48em) {
          // margin-left: auto;
          width: 100%;
          align-items: stretch;
          min-height: 4.5rem;

          .pagination-fragment-index-wrap {
            margin-left: auto;
            width: max-content;
          }
        }
      }
    }

    .table-content-box {
      flex: 1;
      // background-color: blue;
      display: flex;
      overflow-y: scroll;
      flex-direction: column;

      &::-webkit-scrollbar {
        display: none;
      }

      .big-table {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;

        .no-search-empty-box {
          min-height: 25rem;
          width: 100%;
          // background-color: red;
        }

        &::-webkit-scrollbar {
          display: none;
        }
        //   .table thead tr {
        //     & > *:nth-last-child(2) {
        //       //   text-align: center;

        //     }
        //   }

        .table thead {
          // background-color: red !important;
          position: sticky;
          top: 0;
          left: 0;
        }

        @media only screen and (max-width: 48em) {
          display: none;
        }
      }
    }
  }
}
