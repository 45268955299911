.per-page-wrapper-class-index {
  max-width: 10rem;
}
.per-page-wrapper-class-index .react-select-class > *:nth-child(3) {
  display: flex;
  flex-direction: row;
  background-color: unset;
}
.per-page-wrapper-class-index .css-1fdsijx-ValueContainer {
  padding-right: unset !important;
}/*# sourceMappingURL=PerPageComponent.css.map */