.pagination-fragment-index-wrap {
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: flex-end;
  gap: .4rem;
  // margin: 1rem 0rem;
  padding: 0rem 1.5rem;
  border-radius: 1.2rem;
  align-self: stretch;

  .left-icon-box,
  .right-icon-box {
    // border-width: 0.15rem;
    border-radius: 0.4rem;
    width: 2rem;
    height: 2rem;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: transform .3s ease-in-out;

    .img-box {
      width: 100%;
      height: 100%;

      .img {
        width: 100%;
        height: 100%; 
        object-fit: contain;
      }
    }
  }

  .left-icon-box:hover{
    transform: translateX(-.1rem);
  }

  .right-icon-box:hover{
    transform: translateX(.1rem);
  }

  .disabled{
    opacity: .6;
    cursor: not-allowed;

    &:hover{
        transform: unset;
    }
  }
}
