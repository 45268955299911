.transaction-detail-modal-index-wrap {
  min-height: 35rem;
  display: flex;
  flex-direction: column;
}
.transaction-detail-modal-index-wrap .title {
  font-weight: 600;
  font-size: 1.65rem;
}
.transaction-detail-modal-index-wrap .switch-list-box {
  display: flex;
  align-items: center;
  padding: 0.9rem 1rem;
  border-radius: 0.8rem;
  gap: 2rem;
  margin: 1.5rem 0rem;
}
.transaction-detail-modal-index-wrap .switch-list-box .switch-item {
  padding: 0.8rem 1.2rem;
  cursor: pointer;
  border-radius: 1.2rem;
}
.transaction-detail-modal-index-wrap .switch-list-box .switch-item span {
  font-size: 1.35rem;
}
.transaction-detail-modal-index-wrap .switch-list-box .switch-item-active {
  cursor: default;
}
.transaction-detail-modal-index-wrap .switch-list-box .switch-item-active span {
  color: #002856 !important;
  font-weight: 600;
}
.transaction-detail-modal-index-wrap .content-basic-device-wrap {
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
}
.transaction-detail-modal-index-wrap .content-basic-device-wrap .label-value-box {
  display: flex;
  align-items: center;
  padding: 1.5rem 1.2rem;
}
.transaction-detail-modal-index-wrap .content-basic-device-wrap .label-value-box .label {
  flex: 0 0 25%;
}
.transaction-detail-modal-index-wrap .content-basic-device-wrap .label-value-box .value {
  flex: 1;
}
.transaction-detail-modal-index-wrap .content-basic-device-wrap .label-value-box .value span {
  font-size: 1.3rem;
}
.transaction-detail-modal-index-wrap .content-basic-device-wrap > *:last-child {
  border-bottom: unset !important;
}/*# sourceMappingURL=TransactionDetailModal.css.map */