.login-page-index-wrap {
  width: 45rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media only screen and (max-width: 58em){
    width: 100%;
    margin-top: 1rem;
  }

  .form-wrap-box-one {
    width: 100%;
    background-color: #f8fcff;
    padding: 1rem;
    border-radius: 1.2rem;

    .inner-wrap {
      width: 100%;
      min-height: 35rem;
      border-radius: 1.2rem;
      background-color: #ffffff;
      padding: 2rem 0rem;
      display: flex;
      flex-direction: column;

      .sign-title {
        font-size: 2.4rem;
        font-weight: 600;
        margin-bottom: 3rem;
        padding: 0rem 2rem;
      }

      .form {
        padding: 0rem 2rem;
      }

      .btn-submit-box {
        margin-top: auto;
        padding: 1.5rem 0rem 0rem 0rem;
        border-top: 0.15rem solid rgba(208, 213, 221, 0.5);

        .btn {
          .input-submit {
            background: var(--primary-base-color);
          }
        }
      }
    }
  }

  .contact-box-wrap {
    margin-top: auto;
    display: flex;
    align-items: center;
    width: 100%;
    // background-color: blue;
    justify-content: center;

    .text {
      text-align: center;

      span {
        font-weight: 700;
        cursor: pointer;
        color: var(--primary-base-color);
        margin-left: 0.5rem;
      }
    }
  }
}


.form-modal-auth-login {
    // display: none;
    // padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    // background-color: red;
  
    .select-wrap-account-auth {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      max-height: 35rem;
      overflow-y: scroll;
  
      &::-webkit-scrollbar {
        display: none;
      }
  
      .select-item {
        display: flex;
        align-items: center;
        padding: 1rem;
        border-radius: 0.8rem;
        border: 0.2rem solid rgba(238, 238, 238, 1);
        cursor: pointer;
  
        .name {
          color: #020202;
          margin-left: 1.5rem;
          display: flex;
          align-items: center;
  
          .status-type-wrap {
            background-color: #fff6ed;
            border-radius: 3rem;
            width: -moz-max-content;
            width: max-content;
            padding: 0.8rem 1.2rem;
            color: #ea872d;
            margin-left: 2rem;
  
            span {
              font-size: 1.2rem;
            }
          }
        }
  
        .check-wrap {
          margin-left: auto;
          border: 0.2rem solid rgba(238, 238, 238, 1);
          border-radius: 50%;
          width: 2.5rem;
          height: 2.5rem;
          position: relative;
  
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40%;
            height: 40%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(255, 255, 255, 1);
          }
        }
  
        .check-wrap-active {
          background-color: rgba(117, 90, 226, 1);
        }
  
        .img-avatar-wrap {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          overflow: hidden;
          // margin-right: 1.5rem;
  
          .img-box {
            width: 100%;
            height: 100%;
  
            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
  
          .avatar-box {
            width: 100%;
            height: 100%;
            background-color: #020202;
            display: flex;
            justify-content: center;
            align-items: center;
  
            span {
              font-weight: 500;
              font-size: 1.2rem;
              color: #ffffff;
              display: inline-block;
            }
          }
  
          .avatar-box-two {
            background-color: unset;
          }
        }
      }
  
      // .select-item-two{
      //   border-color: unset !important;
  
      //   .name{
      //     color: unset;
      //   }
      // }
    }
  
    .select-wrap-account-auth-in-app {
      // height: 35rem;
      // background-color: red;
      // margin-bottom: 2.5rem;
      // padding-bottom: 2rem;
  
      .select-item {
        //  border: ;
  
        .img-avatar-wrap {
          // background-color: #020202 !important;
  
          .img-box {
            background-color: #020202 !important;
          }
  
          .avatar-box {
            background-color: #020202 !important;
          }
        }
      }
    }
  
    .pin-group {
      // width: 85%;
      .pin_field_group {
        width: 85%;
      }
  
      .black-white-color {
        color: #020202;
      }
  
      .resend-text {
        color: var(--primary-purple);
        margin-bottom: 0rem;
        align-self: flex-start;
        margin-top: 2rem;
        cursor: pointer;
        font-weight: 600;
        // padding-top: 2rem;
      }
    }
  
    .resend-text {
      color: var(--primary-purple);
      margin-bottom: 0rem;
      align-self: flex-end;
      cursor: pointer;
      font-weight: 600;
    }
  
    .text-box {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 0.5rem;
      margin-bottom: 2rem;
  
      .small-title {
        color: #676767;
        font-size: 1.45rem;
        font-weight: 400;
  
        span {
          color: var(--primary-purple);
          font-weight: 600;
        }
      }
  
      .big-title {
        font-size: 2rem;
        color: #020202;
        font-weight: 700;
  
        // @media only screen and (max-width: 42em) {
        //   font-size: 1.5rem;
        //   display: none;
        // }
      }
    }
  }