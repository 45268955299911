.loading-congig-wrap-inderx-page {
  position: fixed;
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}
.loading-congig-wrap-inderx-page .loading-wrap {
  width: 20rem;
  height: 20rem;
}
.loading-congig-wrap-inderx-page .loading-wrap .img-box {
  width: 100%;
  height: 100%;
}
.loading-congig-wrap-inderx-page .loading-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=LoadingConfigPage.css.map */