.title-text-index-box-wrap {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  z-index: 20;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap {
    gap: 1rem;
  }
}
.title-text-index-box-wrap .back-icon-box {
  width: 3.7rem;
  height: 3.7rem;
  background: linear-gradient(to bottom, #002856, #001833);
  display: grid;
  place-items: center;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 1rem;
  transition: all 0.3s ease-in-out;
}
.title-text-index-box-wrap .back-icon-box:hover {
  transform: translateX(-0.25rem);
}
.title-text-index-box-wrap .back-icon-box .img-box {
  width: 50%;
  height: 50%;
}
.title-text-index-box-wrap .back-icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-index-box-wrap .title-text-box {
  display: flex;
  flex-direction: column;
  width: -moz-max-content;
  width: max-content;
  flex: 1;
}
.title-text-index-box-wrap .title-text-box .title {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 0.45rem;
  line-height: 2.5rem;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .title-text-box .title {
    font-size: 1.8rem;
  }
}
.title-text-index-box-wrap .title-text-box .text {
  font-size: 1.35rem;
  text-align: center;
  text-wrap: nowrap;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .title-text-box .text {
    text-align: left;
    text-wrap: unset;
  }
}
.title-text-index-box-wrap .children-box {
  width: 100%;
  display: flex;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .children-box {
    width: -moz-max-content;
    width: max-content;
    margin-left: auto;
  }
}
.title-text-index-box-wrap .children-box .btn-insight {
  border-color: var(--primary-purple);
  border-width: 0.1rem;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .children-box .btn-insight {
    padding: 0.8rem 1rem !important;
  }
}
.title-text-index-box-wrap .children-box .btn-insight .img-box {
  width: 1.8rem;
  height: 1.8rem;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .children-box .btn-insight .img-box {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.title-text-index-box-wrap .children-box .btn-insight .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-index-box-wrap .children-box .btn-insight .text {
  color: var(--primary-purple);
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .children-box .btn-insight .text {
    display: none;
  }
}
.title-text-index-box-wrap .children-box .settings-icon-drop-box-wrap {
  display: flex;
  margin-left: auto;
  position: relative;
  z-index: 10;
}
.title-text-index-box-wrap .children-box .settings-icon-drop-box-wrap .drop-down-icon-box {
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
  position: absolute;
  top: 100%;
  right: 0%;
  padding: 2rem 1.5rem;
  width: -moz-max-content;
  width: max-content;
  border-radius: 1.6rem;
  border-width: 0.15rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.title-text-index-box-wrap .children-box .settings-icon-drop-box-wrap .drop-down-icon-box .icon-text-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.title-text-index-box-wrap .children-box .settings-icon-drop-box-wrap .drop-down-icon-box .icon-text-box .text {
  font-size: 1.55rem;
}
.title-text-index-box-wrap .children-box .settings-icon-drop-box-wrap .drop-down-icon-box .icon-text-box .icon-img-box .img-box {
  width: 2rem;
  height: 2rem;
}
.title-text-index-box-wrap .children-box .settings-icon-drop-box-wrap .drop-down-icon-box .icon-text-box .icon-img-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-index-box-wrap .children-box .settings-icon-drop-box-wrap .icon-box-wrap {
  width: 3.5rem;
  height: 3.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  border-width: 0.15rem;
}
.title-text-index-box-wrap .children-box .settings-icon-drop-box-wrap .icon-box-wrap .img-box {
  width: 55%;
  height: 55%;
}
.title-text-index-box-wrap .children-box .settings-icon-drop-box-wrap .icon-box-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-index-box-wrap .children-box .filter-by-duration-box {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.8rem;
  margin-left: auto;
  cursor: pointer;
}
.title-text-index-box-wrap .children-box .filter-by-duration-box .text {
  font-weight: 600;
}
.title-text-index-box-wrap .children-box .filter-by-duration-box .img-wrap-filter .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.title-text-index-box-wrap .children-box .filter-by-duration-box .img-wrap-filter .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-index-box-wrap .children-box .filter-by-duration-box .react-select-class > *:nth-child(3) {
  width: 12rem;
  border: unset !important;
}
.title-text-index-box-wrap .children-box .filter-by-duration-box .react-select-class > *:nth-child(3) .css-tj5bde-Svg {
  display: none !important;
}
.title-text-index-box-wrap .children-box .filter-by-duration-box .react-select-class > *:nth-child(3) .css-1u9des2-indicatorSeparator,
.title-text-index-box-wrap .children-box .filter-by-duration-box .react-select-class > *:nth-child(3) .css-1xc3v61-indicatorContainer {
  display: none !important;
}
.title-text-index-box-wrap .children-box .filter-by-duration-box .react-select-class > *:nth-child(4) {
  width: 16rem;
  left: unset !important;
  right: -3rem !important;
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}/*# sourceMappingURL=TitleTextBox.css.map */