.dashboard-layout-index-wrap {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  .header-wrap-index-box {
    min-height: 6.5rem;
    // background: linear-gradient(to bottom, #002856, #001833);
    width: 100%;
    position: relative;
    z-index: 9;
    

    .size-wrap-global{
        height: 100%;
    }
  }

  .main-wrap-index-box {
    flex: 1;
    overflow-y: scroll;
    padding: 2rem 0rem 1rem 0rem;
    // background-color: red;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */ 
    position: relative;
    z-index: 8;

    &::-webkit-scrollbar {
      display: none;
    }

    .size-wrap-global-two{
        // background-color: blue;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    // .child {
    //   height: 250rem;
    //   background-color: green;
    //   width: 100%;
    // }
  }

  .footer-wrap-index-box {
    min-height: 5rem;
    display: flex;
    justify-content: center;
    // background-color: red;
    padding: 0rem 0rem 1.2rem 0rem;

    .size-wrap-global {
      display: flex;
      justify-content: center;

      .power-by-box {
        width: max-content;
        cursor: pointer;
        padding: 1rem 2rem;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        // gap: .3rem;

        .text {
          font-size: 1.15rem;
        }

        .img-wrap-box {
          .img-box {
            width: 8rem;
            height: 1.2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  // .size-wrap-global{

  // }
}
