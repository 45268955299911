.header-nav-index-wrap {
  //   background-color: red;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .mobile-slide-bar-toggle-box {
    @media only screen and (min-width: 48em) {
      display: none;
    }

    .icon {
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      color: #ffffff;
    }
  }

  .side-nav-mobile-wrap-box {
    @media only screen and (min-width: 48em) {
      display: none;
    }
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    transform: translateX(-100%);

    .inner-wrap {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      background-color: #020202;
      opacity: 0.4;
      z-index: 2;
    }

    .side-list-box {
      width: 27rem;
      height: 100%;
      position: relative;
      z-index: 3;
      //   background-color: var(--primary-base-color);
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      padding: 3rem 0rem 5rem 2.5rem;
      padding-right: unset;

      .logo-text-box {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        // background-color: red;

        .logo-box {
          .img-box {
            width: 3.5rem !important; 
            height: 3.5rem !important;
            border-radius: 50%;
            display: grid;
            place-items: center;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain !important;
              border-radius: 50%;
            }
          }
        }

        .logo-text {
          padding-left: 1.3rem;
          border-left: 0.15rem solid rgba(255, 255, 255, 0.6);
          .text {
            color: #ffffff;
            font-size: 1.3rem;
          }
        }

        .logo-box {
          margin-left: -1.3rem;
          .img-box {
            width: 10rem;
            height: 2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .link-list-box {
        margin: 0rem auto;
        display: flex;
        align-items: center;
        gap: 2rem;

        @media only screen and (max-width: 48em) {
          display: flex;
          flex-direction: column;
          margin-top: 8rem;
          align-items: stretch;
          //   background-color: red;
          width: 100%;
        }

        .link-item {
          padding: 1.5rem 0rem;
          cursor: pointer;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0% !important;
            height: 100%;
            width: 0.6rem;
            background-color: #ffffff;
            transform: translateY(-50%);
            visibility: hidden;
            left: unset;
          }

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(
              269.91deg,
              rgba(255, 255, 255, 0.1) 22.13%,
              rgba(255, 255, 255, 0) 96.37%
            );
            transform: translateY(-50%);
            visibility: hidden;
          }

          .link {
            color: #ffffff;
            font-size: 1.3rem;
            position: relative;
            z-index: 2;
          }
        }

        .link-item-active {
          cursor: default;

          .link {
            font-weight: 700;
          }

          &::after,
          &::before {
            visibility: visible;
          }
        }
      }

      .theme-wrap-box {
        display: flex;
        align-items: center;
        background-color: rgba(247, 248, 247, 0.12);
        padding: 0.8rem 1.5rem;
        gap: 1rem;
        border-radius: 0.8rem;

        @media only screen and (max-width: 48em) {
          display: flex;
          width: max-content;
          margin-top: auto;
          align-self: center;
          margin-bottom: 5rem;
        }

        .theme-nav-box {
          width: 5rem;
          height: 4.5rem;
          //   background-color: red;
          display: grid;
          place-items: center;
          position: relative;
          cursor: pointer;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.23);
            border-radius: 0.8rem;
            visibility: hidden;
          }

          .img-box {
            width: 65%;
            height: 65%;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .theme-nav-box-active {
          cursor: default;
          &::after {
            visibility: visible;
          }
        }
      }
    }
  }

  .logo-text-box {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    // background-color: red;

    .logo-text {
      padding-left: 1.3rem;
      border-left: 0.15rem solid rgba(255, 255, 255, 0.6);
      .text {
        color: #ffffff;
        font-size: 1.3rem;
      }
    }

    .logo-box {
      // margin-left: -1.3rem;
      .img-box {
        width: 10rem;
        height: 2rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .logo-box {
      margin-right: 1rem;
      .img-box {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        display: grid;
        place-items: center;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }
    }
  }

  .link-list-box {
    margin: 0rem auto;
    display: flex;
    align-items: center;
    gap: 2rem;

    @media only screen and (max-width: 48em) {
      display: none;
    }

    .link-item {
      padding: 1.3rem 2.5rem;
      cursor: pointer;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 0.8rem;
        visibility: hidden;
      }

      .link {
        color: #ffffff;
        font-size: 1.3rem;
        position: relative;
        z-index: 2;
      }
    }

    .link-item-active {
      cursor: default;

      .link {
        font-weight: 600;
      }

      &::after {
        visibility: visible;
      }
    }
  }

  .theme-wrap-box {
    display: flex;
    align-items: center;
    background-color: rgba(247, 248, 247, 0.12);
    padding: 0.7rem 1.2rem;
    gap: 1rem;
    border-radius: 1.6rem;

    @media only screen and (max-width: 48em) {
      display: none;
    }

    .theme-nav-box {
      width: 3.3rem;
      height: 3.3rem;
      //   background-color: red;
      display: grid;
      place-items: center;
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.23);
        border-radius: 0.8rem;
        visibility: hidden;
      }

      .img-box {
        width: 65%;
        height: 65%;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .theme-nav-box-active {
      cursor: default;
      &::after {
        visibility: visible;
      }
    }
  }

  .profile-box-wrap-index {
    margin-left: 3rem;
    position: relative;

    @media only screen and (max-width: 48em) {
      margin-left: auto;
    }

    .profile-drop-box {
      position: absolute;
      top: 110%;
      right: 0%;
      width: max-content;
      border-radius: 0.8rem;
      opacity: 1 !important;
      animation: moveDownSettingDrop 0.5s ease-in-out backwards;
      padding: 1.2rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .icon-text-box {
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        .text {
          font-size: 1.45rem;
        }

        .red {
          color: #ff0f00;
        }

        .img-wrap-box {
          .img-box {
            width: 2.5rem;
            height: 2.5rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    .profile-box-img {
      cursor: pointer;

      .img-box {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;

        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}

@media only screen and (max-width: 48em) {
  .translateX {
    transform: translateX(0%) !important;
  }
}
